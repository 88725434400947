import React, {useEffect, useState} from 'react';
import {Form, Checkbox, Select, Input, Row, Col, Spin, Card} from 'antd';
import useFetch from "../../../../../../hooks/useFetch";
import useMessageUser from "../../../../../../hooks/useMessageUser";
import {FetchApiInterface} from "../../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../../interfaces/Response.interface";
import {useTranslation} from "react-i18next";

const { Option } = Select;
const { TextArea } = Input;

const ProtectionComponent = ({ form, handleChange, formState }: { form: any, handleChange: Function, formState: any }) => {

  const { fetchApi, loading } = useFetch();
  const { showMessage }  = useMessageUser();
  const { t } = useTranslation();
  const [optionsOne, setOptionsOne] = useState([]);
  const [optionsTwo, setOptionsTwo] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/protection`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataOptions = data?.data || [];
          setOptionsOne(dataOptions);
          if (dataOptions.length > 0) {
            handleChange('protection', 'scProtectionFamily', dataOptions[0].spfId);
            /*form.setFieldsValue({
              scProtectionFamily: dataOptions[0].spfId
            });*/
          }
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });

    const dataSendTwo: FetchApiInterface = {
      url: `/comms/protection/familiar-union`,
      method: 'GET'
    }
    fetchApi(dataSendTwo).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataOptions = data?.data || [];
          setOptionsTwo(dataOptions);
          if (dataOptions.length > 0) {
            handleChange('protection', 'familarUnion', dataOptions[0].funId);
            /*form.setFieldsValue({
              familarUnion: dataOptions[0].funId
            });*/
          }

        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const changePscEnable = (option: boolean) => {
    handleChange('protection', 'pscEnable', option);
    if (!option) {
      form.setFieldsValue({
        pscPregnant: false,
        scProtectionFamily: null,
        familarUnion: null,
        pscSexualViolence: false,
        pscLeaving: false,
        pscPhysicalViolence: false,
        pscDetail: null,
      });
      handleChange('protection', 'pscPregnant', false);
      handleChange('protection', 'scProtectionFamily', null);
      handleChange('protection', 'familarUnion', null);
      handleChange('protection', 'pscSexualViolence', false);
      handleChange('protection', 'pscLeaving', false);
      handleChange('protection', 'pscPhysicalViolence', false);
      handleChange('protection', 'pscDetail', null);
    }
  }

  return (
    <Spin spinning={loading}>
      <Form.Item name="pscEnable" valuePropName="checked">
        <Checkbox onChange={(e) => changePscEnable(e?.target?.checked)}>{t('protection')}</Checkbox>
      </Form.Item>
      {
        (formState?.protection?.pscEnable === true) && (
          <Card>
            <Form.Item name="pscPregnant" valuePropName="checked">
              <Checkbox onChange={(e) => handleChange('protection', 'pscPregnant', e?.target?.checked)}>{t('scPregnant')}</Checkbox>
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={t('scParent')} name="scProtectionFamily" rules={[{ required: true, message: t('codeRequired') }]}>
                  <Select placeholder={t('selectOption')} onChange={(e) => handleChange('protection', 'scProtectionFamily', e)}>
                    {
                      optionsOne.map((option: any) => (
                        <Option key={option.spfId} value={option.spfId}>
                          {t(option.spfName)}
                        </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('informalUnionOrMarriage')} name="familarUnion" rules={[{ required: true, message: t('codeRequired') }]}>
                  <Select placeholder={t('selectOption')} onChange={(e) => handleChange('protection', 'familarUnion', e)}>
                    {
                      optionsTwo.map((option: any) => (
                        <Option key={option.funId} value={option.funId}>
                          {t(option.funName)}
                        </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="pscSexualViolence" valuePropName="checked">
              <Checkbox onChange={(e) => handleChange('protection', 'pscSexualViolence', e?.target?.checked)}>{t('sexualViolenceSuspicion')}</Checkbox>
            </Form.Item>
            <Form.Item name="pscLeaving" valuePropName="checked">
              <Checkbox onChange={(e) => handleChange('protection', 'pscLeaving', e?.target?.checked)}>{t('childNeglectSigns')}</Checkbox>
            </Form.Item>
            <Form.Item name="pscPhysicalViolence" valuePropName="checked">
              <Checkbox onChange={(e) => handleChange('protection', 'pscPhysicalViolence', e?.target?.checked)}>{t('physicalViolenceSuspicion')}</Checkbox>
            </Form.Item>
            <Form.Item label={t('protectionDetailsLabel')} name="pscDetail" rules={[{ required: true, message: t('codeRequired') }]}>
              <TextArea placeholder={t('enterTaskDescription')} rows={4} onChange={(e) => handleChange('protection', 'pscDetail', e.target.value)} />
            </Form.Item>
          </Card>
        )
      }
    </Spin>
  );
};

export default ProtectionComponent;
