import {Button, Col, Form, Input, Row, Grid, message} from "antd";
import {DownloadOutlined } from "@ant-design/icons";
import useFetch from "../../../hooks/useFetch";
import {AxiosResponse} from "axios";
import ButtonUpload from "../ButtonUpload";
import {ResponseInterface} from "../../../interfaces/Response.interface";
import {FetchApiInterface} from "../../../interfaces/FetchApi.interface";
import {useEffect, useState} from "react";
import {LanguageListInterface} from "../../../interfaces/LanguageList.interface";
import useMessageUser from "../../../hooks/useMessageUser";
import {useTranslation} from "react-i18next";

const { useBreakpoint } = Grid;

export function CreateLanguage({ changeAction, getLanguages, edit }: { changeAction: any, getLanguages: any, edit: LanguageListInterface | undefined }) {

  const { md } = useBreakpoint();
  const { downloadFile, fetchApi, loading }  = useFetch();
  const { showMessage }  = useMessageUser();
  const [ form ] = Form.useForm();
  const [ dlTemplate, setDlTemplate ] = useState(false);
  const [ dlCurrentTemplate, setDlCurrentTemplate ] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (edit?.lanId) {
      form.setFieldsValue({
        lanName: edit.lanName
      });
    }
  }, [edit]);

  const handleDownload = () => {
    setDlTemplate(true);
    downloadFile('language/donwload', {}, 'get').then(
      (response: any) => {
        const dateNow     = new Date().toISOString();
        if (response.status === 200 ) {
          const binaryData  = [];
          const filename    = `${dateNow}_plantilla.xlsx`;

          const file = new File([response.data], filename);

          binaryData.push(file);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: response.data.type }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        } else {
          message.error('Error al descargar la plantilla.');
        }
      }
    ).finally(() => setDlTemplate(false));
  }

  const downloadCurrentTemplate = () => {
    setDlCurrentTemplate(true);
    downloadFile(`language/donwload/${edit?.lanExcelName}`, {}, 'get').then(
      (response: any) => {
        if (response.status === 200 ) {
          const binaryData  = [];
          const filename    = `${edit?.lanExcelName}_plantilla.xlsx`;

          const file = new File([response.data], filename);

          binaryData.push(file);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: response.data.type }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        } else {
          message.error('Error al descargar la plantilla.');
        }
      }
    ).finally(() => setDlCurrentTemplate(false));
  }

  const onFinish = (values: any) => {
    const formData = new FormData();
    let url = '/language';
    Object.keys(values).forEach((index: string) => {
      if (values[index]) {
        formData.set(index, values[index]);
      }
    });

    if (edit?.lanId) {
      formData.set('lanId', `${edit.lanId}`);
      url = `${url}/update`;

    }

    const dataSend: FetchApiInterface = {
      url,
      data: formData,
      formData: true
    }

    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          form.resetFields();
          changeAction();
          getLanguages();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  return(
    <Form form={form} layout="vertical" className="mt-1" onFinish={onFinish}>
      <Row>
        <Col span={24}>
          <Row gutter={[ 20, 0 ]}>
            <Col span={24} md={12} lg={6}>
              <Form.Item
                label={t('languageName')}
                name="lanName"
                rules={[{ required: true, message: t('codeRequired') }]}
              >
                <Input placeholder={t('languagePlaceholder')} />
              </Form.Item>
            </Col>
            <Col span={24} md={12} lg={6}>
              <Form.Item
                label={t('languageIcon')}
                name="lanIcon"
                rules={[{ required: (!edit?.lanId), message: t('codeRequired') }]}
              >
                <ButtonUpload name={t('languageIconBtn')} form={form} fieldName='lanIcon' fileType="image/svg+xml" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="mt-1">
          <Row gutter={[ 20, 20 ]}>
            <Col span={24} md={12} lg={6}>
              <Form.Item
                label={t('languagePlantilla')}
              >
                <Button loading={dlTemplate} className={`btn-border-radius ${ (!md) ? 'w-100' : 'min-w-200' }`} onClick={handleDownload}>
                  { t('languagePlantillaBtn') } <DownloadOutlined />
                </Button>
              </Form.Item>
              {
                (edit) && (
                  <Form.Item
                    label={t('languagePlantillaBtn')}
                  >
                    <Button loading={dlCurrentTemplate} className={`btn-border-radius ${ (!md) ? 'w-100' : 'min-w-200' }`} onClick={downloadCurrentTemplate}>
                      { t('languagePlantillaBtn') } <DownloadOutlined />
                    </Button>
                  </Form.Item>
                )
              }

              <Form.Item
                label={t('languageTraduccion')}
                name="plantilla"
                rules={[{ required: (!edit?.lanId), message: t('codeRequired') }]}
                valuePropName='file'
              >
                <ButtonUpload name={t('languageTraduccionBtn')} form={form} fieldName='plantilla' fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="text-center mt-1">
          <Button size="large" type="primary" htmlType='submit' className="btn-border-radius" loading={loading}>
            {t('languageBtn')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
