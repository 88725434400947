import {Form, Select} from "antd";
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import GlobalContex from "../../Store/GlobalContext";
import {FetchApiInterface} from "../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../interfaces/Response.interface";
import useMessageUser from "../../hooks/useMessageUser";
import useFetch from "../../hooks/useFetch";

const { Option } = Select;

export interface IPuOnly {
  form: any,
  puIdSelected?: any,
  name?: string,
  isRequired?: boolean,
  dpaId?: number,
}
function PuOnly({ name, isRequired, puIdSelected, dpaId }: IPuOnly) {
  const { t } = useTranslation();
  const { user: { auth } } = useContext(GlobalContex);
  const { showMessage }  = useMessageUser();
  const { fetchApi, loading } = useFetch();
  const [dpaList, setDpaList] = useState([]);

  useEffect(() => {
    if (dpaId) {
      getPus(dpaId);
    } else if (auth?.user?.dpa?.dpaIdCountry) { // revisar si es necesario
      getPus(auth?.user?.dpa?.dpaIdCountry);
    }
  }, [auth, dpaId]);

  const getPus = (dpaIdCountry: number) => {
    const data = {
      dpaFatherId: dpaIdCountry,
      statusRegister: true,
    }
    const dataSend: FetchApiInterface = {
      url: `/catalogs/dpa/get_dpa`,
      data
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataToList = data?.data || [];
          if (dataToList?.length) {
            setDpaList(dataToList);
          } else {
            showMessage(t('dpaNotFound'), 'info');
          }
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  return (
    <div>
      <Form.Item
        label={t('dpaPuLabelName')}
        name={name || 'dpaId'}
        rules={[
          {
            required: (isRequired),
            message: t('codeRequired')
          }
        ]}
      >
        <Select
          placeholder={t('dpaPlaceholderNameSingle')}
          allowClear
          showSearch
          onChange={(puIdSelected) ? (value) => puIdSelected(value) : () => {}}
        >
          {
            dpaList.map((item: any) => (
              <Option key={item.dpaId} value={item?.dpaId}>{ item.dpaName }</Option>
            ))
          }
        </Select>
      </Form.Item>
    </div>
  );
}
export default PuOnly;
