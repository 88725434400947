import {Button, Col, Form, Input, message, Row, Select} from "antd";
import {FetchApiInterface} from "../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../interfaces/Response.interface";
import useFetch from "../../../hooks/useFetch";
import {useContext, useEffect, useState} from "react";
import {DpaInterface} from "../../../interfaces/DpaInterface";
import useMessageUser from "../../../hooks/useMessageUser";
import {useTranslation} from "react-i18next";
import {USER_INFO} from "../../../config/config";
import GlobalContex from "../../../Store/GlobalContext";
import {hasAccess} from "../../../utils";

const { Option } = Select;

function FormDevice({ data, getRegisters }: { data?: any, getRegisters: any}) {
  const [form] = Form.useForm();
  const { fetchApi } = useFetch();
  const [dataEdit, setDataEdit] = useState<any>({});
  const { showMessage }  = useMessageUser();
  const { t } = useTranslation();
  const [user, setUser] = useState<any>();

  useEffect(() => {
    if(localStorage.getItem(USER_INFO)) {
      const dataUser = JSON.parse(localStorage.getItem(USER_INFO) as string);
      setUser(dataUser);
      if (dataUser?.roles.includes('ROLE_REGION_ADMIN')) {
        const dpaId = dataUser?.user?.dpa?.dpaIdRegion;
        getRegions(dpaId);
      } else {
        getRegions();
      }
    } else {
      getRegions();
    }
    if (data?.devId) {
      getDeviceFromId();
    }
  }, []);

  useEffect(() => {
    if (data?.devId && dataEdit) {
      form.setFieldValue('country', dataEdit?.dpa?.dpaId);
    }
  }, [dataEdit]);

  const [regions, setRegions] = useState<Array<DpaInterface>>( []);
  const [countries, setCountries] = useState<Array<DpaInterface>>( []);

  const onFinish = (values: any) => {
    let dataConfig: any = {
      dpaId: values?.region || user?.user?.dpa?.dpaIdRegion,
      devDescription: values.devDescription,
      devUuid: values.devUuid,
    }
    if (values?.country) {
      dataConfig.dpaId = values?.country
    } else {
      dataConfig.dpaId = user?.user?.dpa?.dpaIdCountry;
    }
    if (data?.devId) {
      dataConfig.devId = data?.devId;
    }
    const dataSend: FetchApiInterface = {
      url: (data?.devId) ? 'catalogs/device/update_device' : `/catalogs/device/save_device`,
      data: dataConfig
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          getRegisters();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const getRegions = (dpa?: number) => {
    setCountries([]);
    form.setFieldValue('country', '');
    let data: any = {
      dpaType: "Region"
    }
    if (dpa) {
      data = {
        dpaFatherId: dpa
      }
    }
    const dataSend: FetchApiInterface = {
      url: `/catalogs/dpa/get_dpa`,
      data
    }
    fetchApi(dataSend ).then(
      ({ data: dataRsp }: AxiosResponse<ResponseInterface>) => {
        if (dataRsp.status === 'success') {
          if (dpa) {
            setCountries(dataRsp?.data);
          } else {
            setRegions(dataRsp?.data);
          }
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const getDeviceFromId = () => {
    const dataSend: FetchApiInterface = {
      url: `/catalogs/device/find/${data?.devId}`
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setDataEdit(data.data);
          const dataEdit = data.data;
          getRegions(dataEdit?.dpa?.dpaFather.dpaId);
          form.setFieldsValue({
            devDescription: dataEdit.devDescription,
            devUuid: dataEdit.devUuid,
            region: dataEdit?.dpa?.dpaFather.dpaId
          });
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  return (
    <Row justify='center'>
      <Col span={24} md={15} lg={13} xxl={10}>
        <Form
          form={form}
          name="deviceForm"
          autoComplete="off"
          layout='vertical'
          onFinish={onFinish}
        >
          {
            (hasAccess(['ROLE_COUNTRY_ADMIN'])) &&(
              <Row gutter={[20, 20]}>
                {
                  (hasAccess(['ROLE_REGION_ADMIN']) && regions.length > 0) && (
                    <Col span={24} md={12}>
                      <Form.Item
                        label={t('deviceTableRegion')}
                        name="region"
                        rules={[{ required: true, message: t('codeRequired') }]}
                      >
                        <Select
                          placeholder="Region"
                          allowClear
                          showSearch
                          onChange={ (e) => getRegions(e) }
                        >
                          {
                            regions.map(region => (
                              <Option key={region.dpaId} value={region.dpaId}>{ region.dpaName }</Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  )
                }
                <Col span={24} md={12}>
                  <Form.Item
                    label={t('deviceTableCountry')}
                    name="country"
                    rules={[{ required: true, message: t('codeRequired') }]}
                  >
                    <Select
                      placeholder={t('dpaPlaceholderNameSingle')}
                      allowClear
                      showSearch
                    >
                      {
                        countries.map(country => (
                          <Option key={country.dpaId} value={country.dpaId}>{ country.dpaName }</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )
          }
          <Form.Item
            label={t('deviceTableDescription')}
            name="devDescription"
            rules={[{ required: true, message: t('codeRequired') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('deviceTableMacAddress')}
            name="devUuid"
            rules={[{ required: true, message: t('codeRequired') }]}
          >
            <Input placeholder='276ed2827he192s' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>{t('save')}</Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default FormDevice;
