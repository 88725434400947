import {useParams} from "react-router-dom";
import {
  Breadcrumb,
  Card,
  Col,
  Row,
  Typography,
} from "antd";
import TableCreateXMLSCU from "../../components/TableCreateXMLSCU";
import {useTranslation} from "react-i18next";

const { Title } = Typography;
const CreateXMLSCU = () => {

  const { key } = useParams();
  const { t } = useTranslation();

  return(
    <Row justify='center'>
      <Col span={24} className='p-1'>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>SCUAPP</Breadcrumb.Item>
              <Breadcrumb.Item>{ key }</Breadcrumb.Item>
            </Breadcrumb>
            <Title level={3}>{ t('titleScuappXMLSCU')}</Title>
          </Col>
          <Col span={24}>
            <Card>
              <Row>
                <Col span={24}>
                  <TableCreateXMLSCU />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CreateXMLSCU;
