import { useEffect, useState } from "react";
import { Form, Select, Spin } from "antd";
import useFetch from "../../hooks/useFetch";
import { AxiosResponse } from "axios";
import { FetchApiInterface } from "../../interfaces/FetchApi.interface";
import { ResponseInterface } from "../../interfaces/Response.interface";
import useMessageUser from "../../hooks/useMessageUser";
import { useTranslation } from "react-i18next";

const { Option } = Select;

interface CountryDpaSelectProps {
  form: any;
  name?: string;
  isRequired?: boolean;
}

function CountryDpaSelect({ form, name = "dpa", isRequired = false }: CountryDpaSelectProps) {
  const { fetchApi, loading } = useFetch();
  const { t } = useTranslation();
  const { showMessage } = useMessageUser();
  const [regionsList, setRegionsList] = useState<any[]>([]);
  const [countriesList, setCountriesList] = useState<any[]>([]);
  const [auth, setAuth] = useState<any>({});

  // Cargar las regiones al montar el componente
  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (auth?.roles) {
      console.log(auth?.roles);
      const hasRegionAdminRole = auth.roles.some((role: any) => role.rolName === 'ROLE_REGION_ADMIN');

      if (hasRegionAdminRole) {
        getRegions();
      } else {
        getCountries(auth?.dpa?.dpaFather?.dpaId);
      }
    }
  }, [auth]);

  const getUserInfo = () => {
    const dataSend: FetchApiInterface = {
      url: `/users/get_user`
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setAuth(dataResponse);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const getRegions = () => {
    const dataSend: FetchApiInterface = {
      url: `/catalogs/dpa/get_dpa`,
      data: {
        dpaType: "Region",
        statusRegister: true,
      },
    };

    fetchApi(dataSend)
      .then(({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === "success") {
          setRegionsList(data.data || []);
        } else {
          showMessage(data?.message, "info");
        }
      })
      .catch((error: any) => {
        showMessage(error?.message, "error");
      });
  };

  const getCountries = (regionId: number) => {
    const dataSend: FetchApiInterface = {
      url: `/catalogs/dpa/get_dpa`,
      data: {
        dpaFatherId: regionId,
        statusRegister: true,
      },
    };

    fetchApi(dataSend)
      .then(({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === "success") {
          setCountriesList(data.data || []);
        } else {
          showMessage(data?.message, "info");
        }
      })
      .catch((error: any) => {
        showMessage(error?.message, "error");
      });
  };

  const handleRegionChange = (dpaId: number) => {
    form.setFieldValue(name, undefined);
    getCountries(dpaId);
  };

  const handleCountryChange = (dpaId: number) => {
    form.setFieldValue(name, dpaId);
  };

  return (
    <Spin spinning={loading}>
      {
        (regionsList.length > 0) && (
          <Form.Item
            label={t('dpaLabelName')}
            name="region"
            rules={[
              {
                required: isRequired,
                message: t('codeRequired'),
              },
            ]}
          >
            <Select
              placeholder={t('dpaPlaceholderNameSingle')}
              allowClear
              showSearch
              onChange={handleRegionChange}
              optionFilterProp="children"
            >
              {regionsList.map((item: any) => (
                <Option key={item.dpaId} value={item.dpaId}>
                  {item.dpaName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )
      }

      {/* Select de País */}
      {(countriesList.length > 0) && (
        <Form.Item
          label={t('dpaLabelName')}
          name={name}
          rules={[
            {
              required: isRequired,
              message: t('codeRequired'),
            },
          ]}
        >
          <Select
            placeholder={t('dpaPlaceholderNameSingle')}
            allowClear
            showSearch
            onChange={handleCountryChange}
            optionFilterProp="children"
          >
            {countriesList.map((item: any) => (
              <Option key={item.dpaId} value={item.dpaId}>
                {item.dpaName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Spin>
  );
}

export default CountryDpaSelect;
