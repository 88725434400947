import {USER_INFO} from "../config/config";

export function hasAccess(restrictedRoles: string[]): boolean {
  const userRoles: string[] = JSON.parse(localStorage.getItem(USER_INFO) as string).roles;
  // Lista de todos los roles
  const ROLESTOTALES: Array<string> = [
    "ROLE_COMMS_USER",
    "ROLE_COUNTRY_ADMIN",
    "ROLE_COUNTRY_PROCESS",
    "ROLE_GLOBAL_ADMIN",
    "ROLE_REGION_ADMIN",
    "ROLE_SYSADMIN",
    "ROLE_GUEST",
    "ROLE_USER",
  ];
  // Crear una nueva lista de roles que elimine los roles que no tienen acceso a la ruta
  const restrictedRolesFiltered = userRoles.filter(role => !restrictedRoles.includes(role));
  // Si tiene acceso a la ruta, retornar true
  return restrictedRolesFiltered.some(role => ROLESTOTALES.includes(role));
}
