import {Avatar, Card, Col, message, Row, Space, Typography} from "antd";
import { UserOutlined, UsergroupDeleteOutlined } from "@ant-design/icons";
import {FetchApiInterface} from "../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../interfaces/Response.interface";
import useFetch from "../../../hooks/useFetch";
import {useEffect, useState} from "react";
import {ScDetailInterface} from "../../../interfaces/scDetail.interface";
import ScDpaList from "../../../interfaces/scDpaList";
import {useTranslation} from "react-i18next";

const { Title, Text } = Typography;

function DoubleScDetail({ scId }: { scId: number }) {

  const { fetchApi } = useFetch();
  const [scDetail, setScDetail] = useState<ScDetailInterface>();
  const [list, setList] = useState<Array<ScDetailInterface>>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getScById();
  }, [scId]);
  const getScById = () => {
    const dataSend: FetchApiInterface = {
      url: `/members/doble_afiliados/${scId}`,
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setScDetail(dataResponse?.afiliado || {});
          setList(dataResponse?.posiblesDoblesAfiliados || []);
        } else {
          message.warning(data.message);
        }
      }
    ).catch((reason: any) => {
      const msg = (reason?.message && reason.message.includes('code_')) ? reason?.message : 'Server error.';
      message.error(msg);
    });
  }

  const getYears = (dateBirthdate: string | undefined) => {
    if (dateBirthdate) {
      const dateYear = new Date(dateBirthdate).getFullYear();
      const currentYear = new Date().getFullYear();

      return `${currentYear - dateYear} años`;
    }
    return '';
  }

  return (
    <Row>

      <Col span={24}>
        <Card className='border-radius'>
          <Title level={3}><UserOutlined /> {t('scDetailTitle')}</Title>
          <Row className='mt-1' gutter={20}>
            <Col span={24} md={8} lg={6} xxl={4} className='text-center'>
              <Space direction='vertical' align='center'>
                <Avatar size={60} className='bg-primary'>
                  { scDetail?.scName?.charAt(0) }
                </Avatar>
                <Text strong>
                  { scDetail?.scName }
                </Text>
                <Text>
                  { scDetail?.scNumber }
                </Text>
              </Space>
            </Col>
            <Col span={24} md={16} lg={18} xxl={20}>
              <Row gutter={[40, 20]}>
                <Col span={24} md={12}>
                  <Row justify='space-between' className='mb-1'>
                    <Col flex='auto'>
                      {t('scDetailGenre')}
                    </Col>
                    <Col>
                      <Text strong>{ scDetail?.scGenre }</Text>
                    </Col>
                  </Row>
                  <Row justify='space-between' className='mb-1'>
                    <Col flex='auto'>
                      {t('scDetailBornDate')}
                    </Col>
                    <Col>
                      <Text strong>{ scDetail?.scBornDate }</Text>
                    </Col>
                  </Row>
                  <Row justify='space-between' className='mb-1'>
                    <Col flex='auto'>
                      {t('scDetailAge')}
                    </Col>
                    <Col>
                      <Text strong>{ getYears(scDetail?.scBornDate) }</Text>
                    </Col>
                  </Row>
                  <Row justify='space-between' className='mb-1'>
                    <Col flex='auto'>
                      {t('scDetailScNumber')}
                    </Col>
                    <Col>
                      <Text strong>{ scDetail?.scNumber }</Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} md={12} className='pl-2'>
                  <ScDpaList dpa={scDetail?.dpa || null} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <br/>
        <Title level={3}><UsergroupDeleteOutlined /> {t('scDoubleTitleList')}</Title>
        <br/>
        {
          list.map((item, index) => (
            <Card key={index} className='border-radius mb-2 bg-wen-hot'>
              <Row className='mt-1' gutter={20}>
                <Col span={24} md={8} lg={6} xxl={4} className='text-center'>
                  <Space direction='vertical' align='center'>
                    <Avatar size={60} className='bg-primary'>
                      { item?.scName?.charAt(0) }
                    </Avatar>
                    <Text strong>
                      { item?.scName }
                    </Text>
                    <Text>
                      { item?.scNumber }
                    </Text>
                  </Space>
                </Col>
                <Col span={24} md={16} lg={18} xxl={20}>
                  <Row gutter={[40, 20]}>
                    <Col span={24} md={12}>
                      <Row justify='space-between' className='mb-1'>
                        <Col flex='auto'>
                          {t('scDetailGenre')}
                        </Col>
                        <Col>
                          <Text strong>{ item?.scGenre }</Text>
                        </Col>
                      </Row>
                      <Row justify='space-between' className='mb-1'>
                        <Col flex='auto'>
                          {t('scDetailBornDate')}
                        </Col>
                        <Col>
                          <Text strong>{ item?.scBornDate }</Text>
                        </Col>
                      </Row>
                      <Row justify='space-between' className='mb-1'>
                        <Col flex='auto'>
                          {t('scDetailAge')}
                        </Col>
                        <Col>
                          <Text strong>{ getYears(item?.scBornDate) }</Text>
                        </Col>
                      </Row>
                      <Row justify='space-between' className='mb-1'>
                        <Col flex='auto'>
                          {t('scDetailScNumber')}
                        </Col>
                        <Col>
                          <Text strong>{ item?.scNumber }</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} md={12} className='pl-2'>
                      <ScDpaList dpa={item?.dpa || null} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          ))
        }
      </Col>

    </Row>
  );
}

export default DoubleScDetail;
