import {
  Badge,
  Button,
  Checkbox,
  Col, Divider,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography
} from "antd";
import {FetchApiInterface} from "../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../interfaces/Response.interface";
import useFetch from "../../../hooks/useFetch";
import {useContext, useEffect, useState} from "react";
import useMessageUser from "../../../hooks/useMessageUser";
import {useTranslation} from "react-i18next";
import GlobalContex from "../../../Store/GlobalContext";
import {hasAccess} from "../../../utils";
import PuOnly from "../PuOnly";
import {EditOutlined, CloseOutlined} from "@ant-design/icons";
import RegionDpaSelect from "../RegionDpaSelect";
import CountryDpaSelect from "../CountryDpaSelect";

const { Option } = Select;
const { Paragraph } = Typography;

function FormUser({ data, getRegisters }: { data?: any, getRegisters: any}) {
  const [form] = Form.useForm();
  const { fetchApi } = useFetch();
  const { user: { auth } } = useContext(GlobalContex);
  const [dataEdit, setDataEdit] = useState<boolean>(false);
  const [roles, setRoles] = useState<Array<any>>([]);
  const [groups, setGroups] = useState<Array<any>>([]);
  const { showMessage }  = useMessageUser();
  const { t } = useTranslation();
  const [roleSelected, setRoleSelected] = useState<string | null>(null);
  const [disableUser, setDisableUser] = useState<boolean>(true);
  const [isCommsUser, setIsCommsUser] = useState<boolean>(false);
  const [userDataEdit, setUserDataEdit] = useState<any>({});
  const [changeRole, setChangeRole] = useState<boolean>(false);

  useEffect(() => {
    getRoles();
    getGroups();
    if(Object.keys(data).length) {
      setUserDataEdit(data);
      setDataEdit(true);
      if (data?.hasComms) {
        setIsCommsUser(true);
      }
      form.setFieldsValue({
        ...data,
        statusRegister: true,
        groId: data?.group?.groId,
        puId: data?.puDpa?.dpaId,
        // role: data?.roles[0]?.rolId,
      });
    }
  }, []);

  const onFinish = (values: any) => {
    const userEmail = values.useEmail?.trim();
    const emailDomain = 'plan-international.org';
    // eliminamos el puId si no existe o es undefined
    if (!values?.puId) {
      delete values.puId;
    }
    // buscamos que el email tenga la terminación correcta plan-international.org
    if (values?.role) {
      if (!userEmail.endsWith(emailDomain) && values.role !== 6 && values?.statusRegister === true) {
        notification.error({
          message: t('emailInvalid'),
          description: t('emailInvalidDescription'),
        });
        return;
      }
    }
    let dataConfig: any = {
      "useName": values.useName?.trim(),
      "useLastName": values.useLastName?.trim(),
      "useEmail": values.useEmail?.trim(),
      "dpaId": values?.dpa || userDataEdit?.dpa?.dpaId,
      "statusRegister": values.statusRegister,
      "hasComms": !!values.hasComms,
      "hasAbc": !!values.hasAbc,
    }
    if(values?.role) {
      dataConfig.rolId = values.role;
    }
    if(values?.puId) {
      dataConfig.puId = values?.puId;
    }
    if (auth?.roles.includes('ROLE_COUNTRY_ADMIN') || auth?.roles.includes('ROLE_COUNTRY_PROCESS')) {
      dataConfig.dpaId = auth?.user?.dpa?.dpaIdCountry;
    }
    if (dataEdit) {
      dataConfig.usuId = data.useId;
    }
    if (values?.hasComms) {
      dataConfig.groId = values.groId;
      dataConfig.useCwCode = values.useCwCode;
    }
    if (values.role === 1 || values.role === 2) {
      dataConfig.dpaId = auth?.user?.dpa?.dpaIdRegion;
    }

    const dataSend: FetchApiInterface = {
      url: (!dataEdit) ? '/users/save_user' : `/users/update_user`,
      data: dataConfig
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          getRegisters();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      if (reason?.data) {
        const errorFields = Object.entries(reason?.data || {}).map(([name, errors]) => ({
          name,
          errors: Array.isArray(errors) ? errors : [errors],
        }));

        // Establecer los errores en el formulario
        form.setFields(errorFields);
      }
      showMessage(reason?.message, 'error');
    });
  }

  const getRoles = () =>  {
    const dataSend: FetchApiInterface = {
      url: `/users/rol`,
      data
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          if ( auth?.roles.includes('ROLE_SYSADMIN') || auth?.roles.includes('ROLE_GLOBAL_ADMIN')) {
            setRoles(data?.data);
          }
          if (auth?.roles.includes('ROLE_REGION_ADMIN')) {
            const filteredRoles = data?.data?.filter((role: any) => {
              const roleNamesToInclude = [
                'ROLE_COUNTRY_ADMIN',
                'ROLE_USER',
                'ROLE_GUEST',
                'ROLE_COUNTRY_PROCESS'
              ];
              return roleNamesToInclude.includes(role.rolName);
            });
            setRoles(filteredRoles);
          }
          if (auth?.roles.includes('ROLE_COUNTRY_ADMIN')) {
            const filteredRoles = data?.data?.filter((role: any) => {
              const roleNamesToInclude = [
                'ROLE_COUNTRY_PROCESS',
                'ROLE_USER',
                'ROLE_GUEST',
              ];
              return roleNamesToInclude.includes(role.rolName);
            });
            setRoles(filteredRoles);
          }
          if (auth?.roles.includes('ROLE_COUNTRY_PROCESS')) {
            const filteredRoles = data?.data?.filter((role: any) => {
              const roleNamesToInclude = [
                'ROLE_USER',
                'ROLE_GUEST',
              ];
              return roleNamesToInclude.includes(role.rolName);
            });
            setRoles(filteredRoles);
          }
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const getGroups = () =>  {
    const dataSend: FetchApiInterface = {
      url: `/catalogs/group`,
      data
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setGroups(data?.data);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const selectedRole = (value: any) => {
    const item = roles.find(value1 => (value1.rolId === value));
    form.resetFields(['dpa']);
    setRoleSelected(item?.rolName);
  }

  const submitUpdateUser = () => {
    form.submit();
  }

  const hasRole = (rolesArray: any[] = []) => {
    return rolesArray?.some((role: any) => role.rolName === 'ROLE_USER') || false;
  }

  return (
    <Row justify='center'>
      <Col span={24} md={20}>
        <Form
          form={form}
          name="deviceForm"
          autoComplete="off"
          layout='vertical'
          onFinish={onFinish}
        >
          <Row>
            <Col span={24}>
              <h2>{ t('BasicData') }</h2>
              <Divider />
              <Row gutter={[20, 10]}>
                <Col span={24} md={12}>
                  <Form.Item
                    label={t('useName')}
                    name="useName"
                    rules={[{ required: true, message: t('codeRequired') }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label={t('useLastName')}
                    name="useLastName"
                    rules={[{ required: true, message: t('codeRequired') }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label={t('useEmail')}
                    name="useEmail"
                    rules={[{ required: true, message: t('codeRequired') }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label={t('statusRegister')}
                    name="statusRegister"
                    valuePropName='checked'
                    initialValue={true}
                  >
                    <Switch onChange={(e) => setDisableUser(e)} />
                  </Form.Item>
                </Col>
                {
                  (!disableUser) && (
                    <Col span={24}>
                      <Paragraph style={{ color: 'red' }}>
                        { t('messageUserDisable') }
                      </Paragraph>
                    </Col>
                  )
                }
              </Row>
            </Col>
            <Col span={24}>
              <h2>{ t('AccessLevel') }</h2>
              <Divider />
              <Row>
                {
                  (dataEdit) && (
                  <Col span={24}>
                    <Row justify='space-between'>
                      <Col>
                        <p>
                          <strong>{ userDataEdit?.dpa?.dpaName }</strong>
                        </p>
                        <div>
                          {userDataEdit?.roles.map((role: any, index: number) => (
                            <p><b>{index + 1}:</b> {role.rolLabel}</p>
                          ))}
                        </div>
                        <br/>
                      </Col>
                      <Col>
                        <Button icon={(changeRole) ? <CloseOutlined /> : <EditOutlined />} onClick={() => setChangeRole(!changeRole)} />
                      </Col>
                    </Row>
                  </Col>
                  )
                }
                {
                  (!dataEdit || changeRole) && (
                    <Col span={24}>
                      <Form.Item
                        label={t('role')}
                        name="role"
                      >
                        <Select
                          placeholder={t('roleLabel')}
                          allowClear
                          showSearch
                          onChange={selectedRole}
                        >
                          {
                            roles.map(region => (
                              <Option key={region.rolId} value={region.rolId}>{ region.rolLabel }</Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                      {
                        (roleSelected && hasAccess(['ROLE_COUNTRY_ADMIN'])) && (
                          <Row gutter={[10, 0]}>
                            <Col span={24}>
                              {
                                (roleSelected === 'ROLE_COUNTRY_ADMIN' || roleSelected === 'ROLE_GUEST' || roleSelected === 'ROLE_USER' || roleSelected === 'ROLE_COUNTRY_PROCESS') && (
                                  <CountryDpaSelect form={form} isRequired={true} />
                                )
                              }
                              {
                                (roleSelected === 'ROLE_REGION_ADMIN') && (
                                  <RegionDpaSelect form={form} isRequired={true}/>
                                )
                              }
                            </Col>
                          </Row>
                        )
                      }
                    </Col>
                  )
                }
                <Col span={24} md={12}>
                  <Form.Item
                    label={t('hasAbcLabel')}
                    name="hasAbc"
                    valuePropName='checked'
                    initialValue={false}
                  >
                    <Checkbox>{t('hasAbcDetail')}</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label={t('hasCommsLabel')}
                    name="hasComms"
                    valuePropName='checked'
                    initialValue={false}
                  >
                    <Checkbox onChange={(e) => setIsCommsUser(e.target.checked)}>{t('hasCommsDetail')}</Checkbox>
                  </Form.Item>
                </Col>
                {
                  (isCommsUser) && (
                    <Col span={24}>
                      <Row gutter={[10, 10]}>
                        <Col span={24} md={12} lg={8}>
                          <Form.Item
                            label={t('useCwCode')}
                            name="useCwCode"
                          >
                            <Input maxLength={3} />
                          </Form.Item>
                        </Col>
                        <Col span={24} md={12} lg={8}>
                          <Form.Item
                            label={t('groId')}
                            name="groId"
                            rules={[{ required: true, message: t('codeRequired') }]}
                          >
                            <Select
                              placeholder={t('groIdPlaceholder')}
                              allowClear
                              showSearch
                            >
                              {
                                groups.map(group => (
                                  <Option key={group.groId} value={group.groId}>{ group.groName }</Option>
                                ))
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                        {
                          (roleSelected === 'ROLE_USER' || hasRole(data?.roles) || userDataEdit?.puDpa) && (
                            <Col span={24} md={12} lg={8}>
                              <PuOnly form={form} name='puId' isRequired={false} dpaId={userDataEdit?.dpa?.dpaId} />
                            </Col>
                          )
                        }
                      </Row>
                    </Col>
                  )
                }
              </Row>
            </Col>
          </Row>

          <Form.Item>
            {
              (dataEdit) ? (
                  <Popconfirm
                    title={t('btnUpdateUserAction')}
                    okText={t('btnYes')}
                    cancelText={t('btnNo')}
                    onConfirm={submitUpdateUser}
                  >
                    <Button type='primary' htmlType='button'>{t('btnSave')}</Button>
                  </Popconfirm>
                ) : (
                <Button type='primary' htmlType='submit'>{t('btnSave')}</Button>
              )
            }
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default FormUser;
