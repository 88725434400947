import {Button, Card, Col, Empty, Form, Radio, Row, Space, Spin, Typography} from "antd";
import { GlobalCard } from "../Components/GlobalCard";
import {useContext, useEffect, useState} from "react";
import useFetch from "../../hooks/useFetch";
import { FetchApiInterface } from "../../interfaces/FetchApi.interface";
import { AxiosResponse } from "axios";
import { ResponseInterface } from "../../interfaces/Response.interface";
import Search from "antd/lib/input/Search";
import { PlusOutlined, DeleteOutlined, ArrowLeftOutlined, FilterOutlined } from "@ant-design/icons";
import TableCustom from "../Components/TableCustom";
import useProcesDataTable from "../../hooks/useProcesDataTable";
import { TableDataInterface } from "../../interfaces/TableData.interface";
import { dataInitial } from "../../config/dataConfig";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useTranslation } from "react-i18next";
import useMessageUser from "../../hooks/useMessageUser";
import {UserInterface} from "../../interfaces/User.interface";
import FormUser from "../Components/Users/FormUser";
import UserDetail from "../Components/Users/UserDetail";
import DpaSelected from "../Components/DpaSelected";
import {hasAccess} from "../../utils";
import GlobalContex from "../../Store/GlobalContext";

const { Title } = Typography;

const dataConfig = [
  {
    key: 'useId',
    title: 'ID',
    hiddenToColumn: true,
  },
  {
    key: 'useName',
    title: 'userName',
  },
  {
    key: 'useLastName',
    title: 'userLasteName',
  },
  {
    key: 'useEmail',
    title: 'useEmail'
  },
  {
    key: 'roles',
    title: 'useRoles'
  },
  {
    key: 'statusRegister',
    title: 'useStatus'
  },
];

const actions = {
  list: {
    key: "list",
    name: "listUser",
    breadcrumb: []
  },
  create: {
    key: "create",
    name: "userForm",
    breadcrumb: [
      {
        children: "Idiomas",
        href: '/',
      },
      {
        children: "Crear idioma"
      }
    ]
  },
  detail: {
    key: "detail",
    name: "detailUser",
    breadcrumb: [
      {
        children: "userDetailTitle",
        href: '/',
      }
    ]
  },
};

function Users() {

  const [form] = Form.useForm();
  const { md, lg } = useBreakpoint();
  const { t } = useTranslation();
  const { user: { auth } } = useContext(GlobalContex);

  const [dataRegisters, setDataRegister] = useState<TableDataInterface>(dataInitial);
  const [expanded, setExpanded] = useState<boolean>( true);
  const [action, setAction] = useState<any>( actions.list);
  const { fetchApi, loading } = useFetch();
  const { processData } = useProcesDataTable();
  const { showMessage }  = useMessageUser();
  const [useId, setUseId] = useState<number>( 0);
  const [edit, setEdit] = useState<any>({});
  const [filterApply, setFilterApply] = useState<any>({});

  useEffect(() => {
    setFilterApply({});
  }, []);

  useEffect(() => {
    if (action.key === "list") {
      setFilterApply({});
    }
  }, [action]);

  useEffect(() => {
    if (lg) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [lg]);

  useEffect(() => {
    processFilter();
  }, [filterApply]);

  const processFilter = (page = 0) => {
    const values = form.getFieldsValue();

    const dataFilter: any = {};

    if (Boolean(values?.search)) {
      dataFilter.search = values.search;
    }

    if (filterApply?.dpas) {
      dataFilter.dpas = filterApply?.dpas || [];
    } else if (values?.dpa) {
      dataFilter.dpas = [
        {
          dpaId: filterApply.dpa,
        }
      ] || [];
    }

    getRegisters({
      ...dataFilter,
      statusRegister: values?.statusRegister,
    }, page);
  }

  const getRegisters = (data: any = {}, page = 0) => {
    setAction(actions.list);
    setEdit({});

    const dataSend: FetchApiInterface = {
      url: `/users/get_users?page=${page}`,
      data: data,
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          const dataProcess = processData(dataResponse?.content || [], dataConfig);
          setDataRegister({
            ...dataProcess,
            paginate: {
              size: dataResponse.size,
              totalElements: dataResponse.totalElements,
              totalPages: dataResponse.totalElements
            },
            actions: [
              ...(
                ( hasAccess(['ROLE_USER', 'ROLE_GUEST', 'ROLE_COUNTRY_PROCESS']))
                  ? [{ action: editRegister, name: t('editUserRegister') }]
                  : []
              ),
              {
                action: showDetailSc,
                name: 'scTableViewDetail'
              }
            ]
          });

        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const showDetailSc = (item: UserInterface) => {
    setUseId(item.useId);
    changeAction('detail');
  }

  const changeAction = (option: string) => {
    // @ts-ignore
    const actionItem = actions[option];
    if (action.key === "list") {
      setEdit({});
    }
    setAction(actionItem);
  }

  const editRegister = (item: UserInterface) => {
    const dataSend: FetchApiInterface = {
      url: `/users/find_by_id/${item.useId}`
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setEdit(dataResponse);
          setAction(actions.create);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const clearFilters = () => {
    form.resetFields();
    setFilterApply({});
  }

  const checkClearText = () => {
    const search  = form.getFieldValue('search');
    if (!Boolean(search)) {
      processFilter();
    }
  }

  return(
    <Row>
      <Col span={24}>
        <Row gutter={[20, 20]} justify="center">
          {
            (action.key === "list") && (
              <Col span={24} lg={6} xxl={5} className={ (lg) ? 'content-layout' : '' }>
                <Card className="h-100">
                  {
                    (!lg) && (
                      <Row justify="end">
                        <Col>
                          <Button icon={<FilterOutlined />} type='primary' ghost onClick={() => setExpanded(!expanded)}/>
                        </Col>
                      </Row>
                    )
                  }
                  {
                    (expanded) && (
                      <Form
                        form={form}
                        name="filterUserForm"
                        layout='vertical'
                      >
                        <Row gutter={[20, 20]}>
                        <Col span={24}>
                          <Typography.Title level={4}>{t('searchTitle')}</Typography.Title>
                          <Form.Item
                            name="search"
                          >
                          <Search allowClear placeholder={t('userSearchPlaceholder')} onSearch={() => processFilter()} onChange={checkClearText}/>
                          </Form.Item>
                        </Col>
                          {
                            (hasAccess(['ROLE_USER', 'ROLE_COUNTRY_ADMIN'])) && (
                              <>
                                <Col span={24}>
                                  <Row justify='space-between' align='middle'>
                                    <Col>
                                      <Typography.Title level={4}>{t('userFilter')}</Typography.Title>
                                    </Col>
                                    <Col>
                                      <Button size='small' type='link' icon={<DeleteOutlined />} onClick={clearFilters} >
                                        <Typography.Text underline>
                                          {t('userCleanFilter')}
                                        </Typography.Text>
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                                {
                                  ((auth?.roles?.includes('ROLE_SYSADMIN') || auth?.roles?.includes('ROLE_GLOBAL_ADMIN') || auth?.roles?.includes('ROLE_REGION_ADMIN'))) && (
                                    <Col span={24}>
                                      <DpaSelected form={form} setFilterApply={setFilterApply} maxLevel={2} filterApply={filterApply} />
                                    </Col>
                                  )
                                }
                              </>
                            )
                          }
                          <Col span={24}>
                            <Form.Item
                              label={<Title level={5}>{t('statusRegisterLabel')}</Title>}
                              name="statusRegister"
                            >
                              <Radio.Group onChange={() => processFilter()}>
                                <Row gutter={[10, 10]}>
                                  <Col span={24}>
                                    <Radio value={true}>{t('statusRegisterActive')}</Radio>
                                  </Col>
                                  <Col span={24}>
                                    <Radio value={false}>{t('statusRegisterInactive')}</Radio>
                                  </Col>
                                </Row>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                      </Row>
                      </Form>
                    )
                  }
                </Card>
              </Col>
            )
          }

          <Col span={23} lg={18} xxl={19} className='py-2' style={{ paddingRight: (lg) ? '30px' : '' }}>
            <GlobalCard breadcrumb={action.breadcrumbs}>
              <Row gutter={[0, 20]} justify="space-between" className='mb-2'>
                <Col flex="auto">
                  <Typography.Title level={4}>{t(action.name)}</Typography.Title>
                </Col>
                <Col>
                  {
                    (action.key === "list") ? (
                      <Space>
                        {
                          (hasAccess(['ROLE_USER', 'ROLE_GUEST', 'ROLE_COUNTRY_PROCESS'])) && (
                            <Button size="large" type="primary" className="btn-border-radius" onClick={() => changeAction('create')}>
                              <PlusOutlined />{ (!md)? null : ` ${t('btnNewUser')}` }
                            </Button>
                          )
                        }

                      </Space>
                    ) : (
                      <Button size="large" type="dashed" danger className="btn-border-radius" onClick={() => changeAction('list')}>
                        <ArrowLeftOutlined />{ (!md)? null : ` ${t('return')}` }
                      </Button>
                    )
                  }
                </Col>
                <Col span={24}>
                  {
                    (action.key === "list") && (
                      <Row gutter={[20, 20]}>
                        <Col span={24}>
                          <Typography.Title level={5}>
                            {t('scTableResult')} ({ dataRegisters.paginate.totalElements })
                          </Typography.Title>
                        </Col>
                        {
                          (dataRegisters.dataSource.length > 0) ? (
                            <Col span={24}>
                              <Spin spinning={loading}>
                                <TableCustom dataTable={dataRegisters} getRegisters={getRegisters} onPageChange={processFilter} />
                              </Spin>
                            </Col>
                          ) : (
                            <Col span={24}>
                              <Empty/>
                            </Col>
                          )
                        }

                      </Row>
                    )
                  }
                  {
                    (action.key === "create") && (
                      <FormUser data={edit} getRegisters={getRegisters} />
                    )
                  }
                  {
                    (action.key === "detail") && (
                      <UserDetail useId={useId} />
                    )
                  }
                </Col>
              </Row>
            </GlobalCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Users;
