import React, {useEffect} from 'react';
import {Form, Checkbox, Input, Card} from 'antd';
import {useTranslation} from "react-i18next";

const { TextArea } = Input;

const HealthComponent = ({ form, handleChange, formState }: { form: any, handleChange: Function, formState: any }) => {
  const { t } = useTranslation();
  const changeHeaEnable = (option: boolean) => {
    handleChange('health', 'heaEnable', option);
    if (!option) {
      form.setFieldsValue({
        heaHealthEmergency: null,
        heaChronicDisease: null,
        heaDisability: null,
        heaDetail: null,
      });
      handleChange('health', 'heaHealthEmergency', null);
      handleChange('health', 'heaChronicDisease', null);
      handleChange('health', 'heaDisability', null);
      handleChange('health', 'heaDetail', null);
    }
  }

  return (
    <div>
      <Form.Item name="heaEnable" valuePropName="checked">
        <Checkbox onChange={(e) => changeHeaEnable(e?.target?.checked)}>{t('health')}</Checkbox>
      </Form.Item>
      {
        (formState?.health?.heaEnable === true) && (
          <Card>
            <Form.Item name="heaHealthEmergency" valuePropName="checked">
              <Checkbox onChange={(e) => handleChange('health', 'heaHealthEmergency', e?.target?.checked)}>{t('healthEmergency')}</Checkbox>
            </Form.Item>
            <Form.Item name="heaChronicDisease" valuePropName="checked">
              <Checkbox onChange={(e) => handleChange('health', 'heaChronicDisease', e?.target?.checked)}>{t('chronicDiseaseWithoutTreatment')}</Checkbox>
            </Form.Item>
            <Form.Item name="heaDisability" valuePropName="checked">
              <Checkbox onChange={(e) => handleChange('health', 'heaDisability', e?.target?.checked)}>{t('disabilityWithoutCare')}</Checkbox>
            </Form.Item>
            <Form.Item label={t('healthDetailsLabel')} name="heaDetails" rules={[{ required: true, message: t('codeRequired') }]}>
              <TextArea placeholder={t('enterTaskDescription')} rows={4} onChange={(e) => handleChange('health', 'heaDetail', e.target.value)} />
            </Form.Item>
          </Card>
        )
      }
    </div>
  );
};

export default HealthComponent;
