import {
  AutoComplete,
  Breadcrumb,
  Button,
  Card, Checkbox,
  Col, Dropdown,
  Form, Input,
  Layout, Menu,
  Modal, Popconfirm,
  Row,
  Space, Spin,
  Table, TableProps, Tag,
  Tooltip,
  Typography
} from "antd";
import {useEffect, useState} from "react";
import {
  EllipsisOutlined,
  SyncOutlined,
  PlusOutlined, DeleteOutlined
} from "@ant-design/icons";
import DpaSelected from "../../../../Components/DpaSelected";
import {IMonitoring} from "../../models";
import useFetch from "../../../../../hooks/useFetch";
import useMessageUser from "../../../../../hooks/useMessageUser";
import {FetchApiInterface} from "../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../interfaces/Response.interface";
import DrawerProjectC2 from "../../components/DrawerProjectC2";
import useExport from "../../../../../hooks/comms/useExport";
import DetailSc6 from "../../components/compromisos/DetailSc6";
import debounce from 'lodash/debounce';
import Filters6 from "../../components/compromisos/Filters6";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {getScopes} from "../../helperComms";
import {InputNumber} from "antd/es";

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { Search } = Input;
const Compromiso6 = () => {
  const { t } = useTranslation();
  const scopes = getScopes();

  const initialColumns = [
    {
      title: t('sc'),  // Reemplaza 'SC'
      dataIndex: 'ascScNumber',
      key: 'ascScNumber',
      visible: true,
      fixed: 'left',
      sorter: true,
    },
    {
      title: t('gender'),  // Reemplaza 'G'
      dataIndex: 'ascScGenre',
      key: 'ascScGenre',
      visible: true,
      render: (item: string) => item === "Male" ? t('M') : t('F'),
      sorter: true,
    },
    {
      title: t('name'),  // Reemplaza 'Nombre'
      dataIndex: 'ascScName',
      key: 'ascScName',
      visible: true,
      sorter: true,
    },
    {
      title: t('com'),  // Reemplaza 'COM'
      dataIndex: 'assComType',
      key: 'assComType',
      visible: true,
      sorter: true,
    },
    {
      title: t('up'),  // Reemplaza 'UP'
      dataIndex: 'ascPuCode',
      key: 'ascPuCode',
      visible: true,
      sorter: true,
    },
    {
      title: t('community'),  // Reemplaza 'Comunidad'
      dataIndex: 'ascLevel',
      key: 'ascLevel',
      visible: true,
      sorter: true,
    },
    {
      title: t('facilitator'),  // Reemplaza 'Facilitador'
      dataIndex: 'ascCw',
      key: 'ascCw',
      visible: true,
      render: (item: string, row: any) => `${row?.ascCwCode} - ${item}`,
      sorter: true,
    },
    {
      title: t('receivedUa'),  // Reemplaza 'Recibido_UA'
      dataIndex: 'conReadDate',
      key: 'conReadDate',
      visible: true,
      render: (item: string) => `${item ? moment(item).format('DD-MM-YYYY') : ''}`,
      sorter: true,
    },
    {
      title: t('expiresCd'),  // Reemplaza 'Vence_CD'
      dataIndex: 'newDate',
      key: 'newDate',
      visible: true,
      render: (item: string) => `${item ? moment(item).format('DD-MM-YYYY') : ''}`,
      sorter: true,
    },
    {
      title: t('statusCom'),  // Reemplaza 'Estatus COM'
      dataIndex: 'status',
      key: 'status',
      visible: true,
      sorter: true,
    },
    {
      title: t('statusCd'),  // Reemplaza 'E_CD'
      dataIndex: 'ascCdStatus',
      key: 'ascCdStatus',
      visible: true,
      render: (item: string) => <Tag>{item.substring(0,4)}</Tag>,
      sorter: true,
    },
    {
      title: t('no'),  // Reemplaza 'NO'
      dataIndex: 'assNo',
      key: 'assNo',
      visible: true,
      render: (item: string) => <Tag color="blue">{item}</Tag>,
      sorter: true,
    }
  ];

  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filterApply, setFilterApply] = useState<any>({});
  const [filterApplyExcel, setFilterApplyExcel] = useState<any>({});
  const [dataFilters, setDataFilters] = useState<any>({});
  const [searchText, setSearchText] = useState<string>('');
  const [columns, setColumns] = useState<Array<any>>(initialColumns);
  const [dataSource, setDataSource] = useState<Array<IMonitoring>>([]);
  const { fetchApi, loading } = useFetch();
  const [openModalFilterExport, setOpenModalFilterExport] = useState(false);
  const [openDetailSc, setOpenDetailSc] = useState(false);
  const [deleteItems, setDeleteItems] = useState<Array<any>>([]);
  const [editItemData, setEditItemData] = useState<any>({});
  const [diffDay, setDiffDays] = useState<number | null>(null);
  const { showMessage }  = useMessageUser();

  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 20,
    total: 0,
  });
  const [form] = Form.useForm();
  const { exportExcel, downloading } = useExport();

  useEffect(() => {
    form.setFieldValue('dpasExcel', null);
    setFilterApplyExcel({});
  }, [openModalFilterExport]);

  useEffect(() => {
    if (!searchText) {
      const newFilterApply = { ...dataFilters };
      delete newFilterApply.search;
      setDataFilters(newFilterApply);
    }
  }, [searchText]);

  useEffect(() => {
    if (!openDrawer) {
      getTabla();
    }
  }, [dataFilters, openDrawer, filterApply]);
  const handleTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    getTabla(pagination.current, pagination.pageSize, sorter);
  };


  const getTabla = (page = 1, size = 20, sorter: any = '') => {
    const sortParams = `${sorter.field},${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    const dateSend = { ...filterApply, search: searchText, sort: sortParams };
    if (sorter.order === undefined) {
      delete dateSend.sort;
    }

    const dataSend: FetchApiInterface = {
      url: `/comms/control/get_view?page=${page - 1}&size=${size}`,
      data: dateSend,
    };

    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setDataSource(dataResponse?.content || []);
          setPagination({
            ...pagination,
            current: data?.data?.pageable?.pageNumber + 1,
            total: data?.data?.totalElements,
            pageSize: size,
          });
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  };

  const handleSearch = (value: string) => {
    if (value) {
      setSearchText(value.trim());
      setDataFilters((prevFilters: any) => ({ ...prevFilters, search: value.trim() }));
    }
  };

  // Función para manejar el cambio en el estado de visibilidad de las columnas
  const handleVisibleChange = (e: any, columnName: any) => {
    const newColumns = columns.map(column => {
      if (column.key === columnName) {
        return { ...column, visible: e.target.checked };
      }
      return column;
    });
    setColumns(newColumns);
  };

  const visibleColumns = columns.filter(column => column.visible);

  // Menú para el Dropdown con Checkboxes para cada columna
  const menu = (
    <Menu>
      {columns.map(column => (
        <Menu.Item key={column.key}>
          <Checkbox
            checked={column.visible}
            onChange={e => handleVisibleChange(e, column.key)}
          >
            {column.title}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  // El Dropdown que muestra el menú
  const dropdown = (
    <Dropdown trigger={['click']} overlay={menu}>
      <Button type='primary' shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );

  const onFinish = (values: any) => {
    // Agregar age unicamente si existe values?.age para evitar error en el backend
    if (values?.age) {
      values.age = { desde: values?.age[0], hasta: values?.age[1] };
    }

    const aux = {
      ...values,
      read: {
        value1: values.read && values.read.value1 ? moment(values.read.value1).format('YYYY-MM-DD') : '',
        value2: values.read && values.read.value2 ? moment(values.read.value2).format('YYYY-MM-DD') : '',
      },
      newDate: {
        value1: values.newDate && values.newDate.value1 ? moment(values.newDate.value1).format('YYYY-MM-DD') : '',
        value2: values.newDate && values.newDate.value2 ? moment(values.newDate.value2).format('YYYY-MM-DD') : '',
      },
    };

    // Validamos si existe read y newDate y sus value1 y 2 no están vacíos, en dado caso eliminamos esas variables para evitar error en el backend
    if (values.read && (!values.read.value1 || !values.read.value2)) {
      delete aux.read;
    }
    if (values.newDate && (!values.newDate.value1 || !values.newDate.value2)) {
      delete aux.newDate;
    }

    setFilterApply(aux);
    setOpenModal(false);
  }

  const cleanFilters = () => {
    setSearchText('');
    form.resetFields();
    setDataFilters({});
    setFilterApply({});
    setOpenModal(false);
  }
  const exportExcelHanddle = async () => {
    const url = `/comms/control/donwload_excel`;
    setOpenModalFilterExport(false);
    await exportExcel(url, filterApply, 'compromiso_seis', 'post');
  }

  const fetchOptions = async (searchTerm: string) => {
    const dataSend: FetchApiInterface = {
      url: `/comms/get_active_sc_number`,
      data: { scNumber: searchTerm },
    }
    const response = await fetchApi(dataSend);
    if (response?.data?.status === 'success') {
      const data = response?.data?.data || [];
      if (!data.length) {
        showMessage('NoDataResponse', 'error');
        return [];
      }
      return data.map((item: any) => ({ value: item }));
    } else {
      showMessage(response?.data?.message || 'NoDataResponse', 'error');
    }
    return [];
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      const selectedIds = selectedRowKeys.map((id: any) => ({ assId: parseInt(id.toString()) }));
      setDeleteItems(selectedIds);
    }
  };

  const deleteConfirm = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/control/delete`,
      data: deleteItems,
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message, 'success');
          getTabla();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const onRowClick = (record: any) => {
    setEditItemData(record);
    setOpenDetailSc(true);
  };

  const updateDiffDays = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/control_days`,
      data: {
        cdaId: 1,
        cdaDay: diffDay,
      },
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setDiffDays(null);
          showMessage(data?.message, 'success');
          getTabla();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  return(
    <Layout>
      <Content>
        <Row justify='center'>
          <Col span={24} className='p-1'>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Breadcrumb>
                  <Breadcrumb.Item>{t('planCommitments')}</Breadcrumb.Item>
                  <Breadcrumb.Item>{t('commitment6')}</Breadcrumb.Item>
                </Breadcrumb>
                <Title level={3}>{t('communicationControl')}</Title>
                <Paragraph strong>
                  {t('communicationCommitmentDescription')}
                </Paragraph>
              </Col>
              <Col span={24}>
                <Card className='border-radius'>
                  <Row gutter={[10, 30]}>
                    {
                      (scopes.includes('COMP_6_DAYS')) && (
                        <Col span={24}>
                          <Space align='center'>
                            <b>{ t('diffDaysLabel') }</b>
                            <InputNumber min={1} value={diffDay} onChange={(num) => setDiffDays(num)} />
                            <Button disabled={(diffDay === null || diffDay < 1)} loading={loading} onClick={updateDiffDays} type='primary'>{ t('btnUpdateDiffDays') }</Button>
                          </Space>
                        </Col>
                      )
                    }
                    <Col span={24}>
                      <Row gutter={[20, 20]} justify='space-between'>
                        <Col>
                          <Row gutter={[10,10]}>
                            <Filters6
                              filterApply={filterApply}
                              onFinish={onFinish}
                              cleanFilters={cleanFilters}
                              openModal={openModal}
                              setOpenModal={setOpenModal}
                            />
                          </Row>
                        </Col>
                        <Col>
                          <Row align='middle' gutter={[20, 20]}>
                            <Col>
                              <Search placeholder={t('searchPlaceholder')} onSearch={handleSearch} allowClear enterButton value={searchText} onChange={(e) => setSearchText(e.target.value) } />
                            </Col>
                            <Col>
                              { dropdown }
                            </Col>
                            <Col>
                              <Tooltip title={t('reloadListTooltip')}>
                                <Button icon={<SyncOutlined />} onClick={() => getTabla(0)} />
                              </Tooltip>
                            </Col>
                            <Col>
                              <Space size={20}>
                                {
                                  (scopes.includes('COMP_6_DESCARGAR')) && (
                                    <Button type='primary' loading={downloading} onClick={() => exportExcelHanddle()} >{t('exportExcel')}</Button>
                                  )
                                }
                                {
                                  (scopes.includes('COMP_6_ELMINAR')) && (
                                    <Popconfirm
                                      title={t('deleteConfirm', { count: deleteItems.length })}
                                      onConfirm={deleteConfirm}
                                      okText={t('yes')}
                                      cancelText={t('no')}
                                      disabled={deleteItems.length <= 0}
                                    >
                                      <Button type='primary' disabled={deleteItems.length <= 0} danger loading={downloading} icon={<DeleteOutlined />} >{t('delete')}</Button>
                                    </Popconfirm>
                                  )
                                }
                              </Space>
                              <Modal open={openModalFilterExport} title={t('exportExcel')} footer={null} onCancel={() => setOpenModalFilterExport(false)}>
                                <Row gutter={[10, 10]}>
                                  <Col span={24}>
                                    <Form form={form} layout='vertical'>
                                      <DpaSelected form={form} name='dpasExcel' setFilterApply={setFilterApplyExcel} filterApply={filterApplyExcel} isRequired={true} />
                                    </Form>
                                  </Col>
                                  <Col span={24}>
                                    <Space>
                                      <Button type='primary' ghost danger onClick={() => setOpenModalFilterExport(false)}>{t('cancel')}</Button>
                                      <Button type='primary' onClick={exportExcelHanddle}>{t('export')}</Button>
                                    </Space>
                                  </Col>
                                </Row>
                              </Modal>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Typography.Title level={5}>
                        {t('scTableResult')} ({ pagination.total })
                      </Typography.Title>
                    </Col>
                    <Col span={24}>
                      <Table
                        rowSelection={(scopes.includes('COMP_6_ELMINAR')) ? {
                          type: 'checkbox',
                          ...rowSelection,
                        } : undefined}
                        dataSource={dataSource}
                        columns={visibleColumns}
                        scroll={{ x: 1200 }}
                        pagination={pagination}
                        onChange={handleTableChange}
                        rowKey='assId'
                        onRow={(record) => {
                          return {
                            onClick: () => {
                              onRowClick(record);
                            }
                          };
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
                {/* Presentar el detalle del SC */}
                <DetailSc6 openDetailSc={openDetailSc} setOpenDetailSc={setOpenDetailSc} data={editItemData} getTabla={getTabla} />

              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Compromiso6;
