import React, {useEffect, useState} from 'react';
import {Form, Checkbox, Select, Input, Spin, Card} from 'antd';
import useFetch from "../../../../../../hooks/useFetch";
import useMessageUser from "../../../../../../hooks/useMessageUser";
import {FetchApiInterface} from "../../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../../interfaces/Response.interface";
import {useTranslation} from "react-i18next";

const { Option } = Select;
const { TextArea } = Input;

const EducationComponent = ({ form, handleChange, formState }: { form: any, handleChange: Function, formState: any }) => {

  const { t } = useTranslation();
  const { fetchApi, loading } = useFetch();
  const { showMessage }  = useMessageUser();
  const [optionsOne, setOptionsOne] = useState([]);
  const [optionsTwo, setOptionsTwo] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/education/grades`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setOptionsOne(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });

    const dataSendTwo: FetchApiInterface = {
      url: `/comms/education/no-study-reason`,
      method: 'GET'
    }
    fetchApi(dataSendTwo).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setOptionsTwo(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const changeEduEnable = (option: boolean) => {
    handleChange('education', 'eduEnable', option);
    if (!option) {
      form.setFieldsValue({
        eduPriorizedScholarship: false,
        grades: null,
        noStudyReason: null,
        eduDetails: null,
      });
      handleChange('education', 'eduPriorizedScholarship', false);
      handleChange('education', 'grades', null);
      handleChange('education', 'noStudyReason', null);
      handleChange('education', 'eduDetails', null);
    }
  }

  return (
    <Spin spinning={loading}>
      <Form.Item name="eduEnable" valuePropName="checked">
        <Checkbox onChange={(e) => changeEduEnable(e?.target?.checked)}>{t('education')}</Checkbox>
      </Form.Item>
      {
        (formState?.education?.eduEnable === true) && (
          <Card>
            <Form.Item name="eduPriorizedScholarship" valuePropName="checked">
              <Checkbox onChange={(e) => handleChange('education', 'eduPriorizedScholarship', e?.target?.checked)}>{t('prioritizedScholarship')}</Checkbox>
            </Form.Item>
            <Form.Item label={t('grades')} name="grades" rules={[{ required: true, message: t('codeRequired') }]}>
              <Select placeholder={t('selectOption')} onChange={(e) => handleChange('education', 'grades', e)}>
                {
                  optionsOne.map((option: any) => (
                    <Option key={option.graId} value={option.graId}>
                      {t(option.graName)}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item label={t('noStudyReason')} name="noStudyReason" rules={[{ required: true, message: t('codeRequired') }]}>
              <Select placeholder={t('selectOption')} onChange={(e) => handleChange('education', 'noStudyReason', e)}>
                {
                  optionsTwo.map((option: any) => (
                    <Option key={option.nsrId} value={option.nsrId}>
                      {t(option.nsrName)}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item label={t('eduDetailsLabel')} name="eduDetails" rules={[{ required: true, message: t('codeRequired') }]}>
              <TextArea placeholder={t('enterTaskDescription')} rows={4} onChange={(e) => handleChange('education', 'eduDetails', e.target.value)} />
            </Form.Item>
          </Card>
        )
      }
    </Spin>
  );
};

export default EducationComponent;
