import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  UploadProps
} from "antd";
import {CameraFilled, PlusOutlined} from "@ant-design/icons";
import UploadSvg from '../../../assets/images/upload-icon.svg';

const { Text, Title, Paragraph } = Typography;
const { Option } = Select;
const { Dragger } = Upload;

function ScForm() {

  const [form] = Form.useForm();

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          form={form}
          name="scForm"
          autoComplete="off"
          layout='vertical'
        >
          <Row gutter={[ 20, 0]}>
            <Col span={24} md={12}>
              <Form.Item
                label='Nombres'
                name="region"
              >
                <Input placeholder='Nombres completos' />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="Apellidos"
                name="region"
              >
                <Input placeholder='Apellidos completos' />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Género"
                name="genre"
              >
                <Checkbox.Group>
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Checkbox value="Masculino">Masculino</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Femenino">Femenino</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Space direction='vertical'>
                    <Text strong><CameraFilled /> Año fiscal actual</Text>
                    <Form.Item
                      label='Tipo de documento'
                      name="document"
                    >
                      <Select
                        placeholder="SCU"
                        allowClear
                        showSearch
                      >
                        <Option value='uno'>uno </Option>
                      </Select>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={24}>
                  <Space direction='vertical'>
                    <Text strong>Foto SC</Text>
                    <Dragger {...props}>
                      <div style={{ paddingTop: '2rem', paddingBottom: '2rem', paddingLeft: '4rem', paddingRight: '4rem' }}>
                        <p className="ant-upload-drag-icon">
                          <img src={UploadSvg} alt=""/>
                        </p>
                        <Title className='color-primary' level={3}>Subir Archivo</Title>
                        <p>
                          Se permiten archivos PNG, JPG
                        </p>
                      </div>
                    </Dragger>
                  </Space>
                </Col>
              </Row>
              <Divider />
            </Col>
            <Col span={24}>
              <Text strong>Foto Familia</Text>
              <Row>
                <Col span={24} md={12}>
                  image
                </Col>
                <Col span={24} md={12}>
                  <Paragraph>Seleccione el familiar con el que se encuentra el SC en la foto y escriba el nombre.</Paragraph>
                  <Row gutter={[10, 10]} align='middle'>
                    <Col>
                      <Form.Item
                        label='Parentesco'
                        name="document"
                      >
                        <Select
                          placeholder="SCU"
                          allowClear
                          showSearch
                        >
                          <Option value='uno'>uno </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label='Nombre'
                        name="document"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label='Parentesco'
                        name="document"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label=' '>
                        <Button type='primary' icon={<PlusOutlined />}>Agregar</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} style={{ paddingLeft: '10px' }} className='bg-secondary border-radius'>
                      <Text>
                        Padre, Nombre Apellido
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

export default ScForm;
