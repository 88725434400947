import React from 'react';
import { Form, Checkbox, DatePicker, Select, Row, Col, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

const { Option } = Select;

const StatusComponent = ({ form, handleChange, formState, statusOptions }: { form: any, handleChange: Function, formState: any, statusOptions: Array<any> }) => {

  const { t } = useTranslation();

  const changeDate = (field: string, date: any) => {
    // formateamos la fecha con moment
    const dateFormatted = moment(date).format('YYYY-MM-DD');
    handleChange('status', field, dateFormatted);
  }

  // Función para deshabilitar las fechas futuras
  const disableFutureDates = (current: any) => {
    return current && current > moment().endOf('day');
  };

  const changeStaIsNoi = (option: boolean) => {
    handleChange('status', 'staIsNoi', option);
    if (!option) {
      form.setFieldsValue({
        staDateNoi: null,
      });
      form.setFieldsValue({ staDateNoi: null });
    }
  }

  return (
    <Spin spinning={false}>
      <Form.Item name="staIsNoi" valuePropName="checked">
        <Checkbox onChange={(e) => changeStaIsNoi(e?.target?.checked)}>{t('noi')}</Checkbox>
      </Form.Item>
      <Form.Item label={t('noiDate')} name="staDateNoi">
        <DatePicker
          disabled={!formState?.status?.staIsNoi}
          style={{ width: '100%' }}
          placeholder="YYYY-MM-DD"
          format="YYYY-MM-DD"
          onChange={(e: any) => changeDate('staDateNoi', e)}
          disabledDate={disableFutureDates}
        />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={t('changeCaseStatus')} name="statusCatalog" rules={[{ required: true, message: t('codeRequired') }]}>
            <Select placeholder={t('selectStatus')} onChange={(e) => handleChange('status', 'statusCatalog', e)}>
              {
                statusOptions.map((option: any) => (
                  <Option key={option.scaId} value={option.scaId}>
                    {t(option.scaName)}
                  </Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('changeDate')} name="staChangeDate" rules={[{ required: true, message: t('codeRequired') }]}>
            <DatePicker
              style={{ width: '100%' }}
              format="DD-MM-YYYY"
              onChange={(e: any) => changeDate('staChangeDate', e)}
              disabledDate={disableFutureDates}
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={{ marginTop: 16 }}>
        <InfoCircleOutlined style={{ marginRight: 8 }} />
        {t('addDetailedFollowUp')}
      </div>
    </Spin>
  );
};

export default StatusComponent;
