import { useEffect, useState } from "react";
import { Form, Select, Spin } from "antd";
import useFetch from "../../hooks/useFetch";
import { AxiosResponse } from "axios";
import { FetchApiInterface } from "../../interfaces/FetchApi.interface";
import { ResponseInterface } from "../../interfaces/Response.interface";
import useMessageUser from "../../hooks/useMessageUser";
import {useTranslation} from "react-i18next";

const { Option } = Select;

interface RegionDpaSelectProps {
  form: any;
  name?: string;
  isRequired?: boolean;
}

function RegionDpaSelect({ form, name = "dpa", isRequired = false }: RegionDpaSelectProps) {
  const { fetchApi, loading } = useFetch();
  const { t } = useTranslation();
  const { showMessage } = useMessageUser();
  const [dpaList, setDpaList] = useState<any[]>([]);

  useEffect(() => {
    getRegions();
  }, []);

  const getRegions = () => {
    const dataSend: FetchApiInterface = {
      url: `/catalogs/dpa/get_dpa`,
      data: {
        dpaType: "Region",
        statusRegister: true,
      },
    };

    fetchApi(dataSend)
      .then(({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === "success") {
          setDpaList(data.data || []);
        } else {
          showMessage(data?.message, "info");
        }
      })
      .catch((error: any) => {
        showMessage(error?.message, "error");
      });
  };

  const handleSelectChange = (dpaId: number) => {
    form.setFieldValue(name, dpaId);
  };

  return (
    <Spin spinning={loading}>
      <Form.Item
        label={t('dpaLabelName')}
        name={name}
        rules={[
          {
            required: isRequired,
            message: t('codeRequired'),
          },
        ]}
      >
        <Select
          placeholder={t('dpaPlaceholderNameSingle')}
          allowClear
          showSearch
          onChange={handleSelectChange}
          optionFilterProp="children"
        >
          {dpaList.map((item: any) => (
            <Option key={item.dpaId} value={item.dpaId}>
              {item.dpaName}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Spin>
  );
}

export default RegionDpaSelect;
