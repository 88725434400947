import {Avatar, Card, Col, Image, message, Row, Space, Table, Typography} from "antd";
import {UserOutlined, UsergroupAddOutlined, CameraOutlined} from "@ant-design/icons";
import {FetchApiInterface} from "../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../interfaces/Response.interface";
import useFetch from "../../../hooks/useFetch";
import {useEffect, useState} from "react";
import {Family, ScDetailInterface} from "../../../interfaces/scDetail.interface";
import ScDpaList from "../../../interfaces/scDpaList";
import {useTranslation} from "react-i18next";
import ImageWithAuth from "../ImageAuth";

const { Title, Text } = Typography;
const currentYear = new Date().getFullYear();

function ScDetail({ scId }: { scId: number }) {

  const { fetchApi } = useFetch();
  const [scDetail, setScDetail] = useState<ScDetailInterface>();
  const { t } = useTranslation();

  useEffect(() => {
    getScById();
  }, [scId]);

  const columns = [
    {
      title: t('scFamilyTableName'),
      dataIndex: 'famName',
      key: 'name',
    },
    {
      title: t('scFamilyTableFamilyMember'),
      dataIndex: 'famType',
      key: 'famType',
      render: (_: any, item: Family) => (
        <>
          { item.famType }
        </>
      )
    },
    {
      title: t('scFamilyTableBornYear'),
      dataIndex: 'famBornYear',
      key: 'famBornYear',
    },
    {
      title: t('scFamilyTableAge'),
      dataIndex: 'edad',
      key: 'edad',
      render: (_: any, item: Family) => (
        <>
          { currentYear - item.famBornYear }
        </>
      )
    },
  ];

  const getScById = () => {
    const dataSend: FetchApiInterface = {
      url: `/members/find_sc/${scId}`,
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setScDetail(dataResponse);
        } else {
          message.warning(data.message);
        }
      }
    ).catch((reason: any) => {
      const msg = (reason?.message && reason.message.includes('code_')) ? reason?.message : 'Server error.';
      message.error(msg);
    });
  }

  const getYears = (dateBirthdate: string | undefined) => {
    if (dateBirthdate) {
      const dateYear = new Date(dateBirthdate).getFullYear();
      const currentYear = new Date().getFullYear();

      return `${currentYear - dateYear} años`;
    }
    return '';
  }

  return (
    <Row>

      <Col span={24}>
        <Card className='border-radius'>
          <Title level={3}><UserOutlined /> {t('scDetailTitle')}</Title>
          <Row className='mt-1' gutter={20}>
            <Col span={24} md={8} lg={6} xxl={4} className='text-center'>
              <Space direction='vertical' align='center'>
                <Avatar size={60} className='bg-primary'>
                  { scDetail?.scName?.charAt(0) }
                </Avatar>
                <Text strong>
                  { scDetail?.scName }
                </Text>
                <Text>
                  { scDetail?.scNumber }
                </Text>
              </Space>
            </Col>
            <Col span={24} md={16} lg={18} xxl={20}>
              <Row gutter={[40, 20]}>
                <Col span={24} md={12}>
                  <Row justify='space-between' className='mb-1'>
                    <Col flex='auto'>
                      {t('scDetailGenre')}
                    </Col>
                    <Col>
                      <Text strong>{ scDetail?.scGenre }</Text>
                    </Col>
                  </Row>
                  <Row justify='space-between' className='mb-1'>
                    <Col flex='auto'>
                      {t('scDetailBornDate')}
                    </Col>
                    <Col>
                      <Text strong>{ scDetail?.scBornDate }</Text>
                    </Col>
                  </Row>
                  <Row justify='space-between' className='mb-1'>
                    <Col flex='auto'>
                      {t('scDetailAge')}
                    </Col>
                    <Col>
                      <Text strong>{ getYears(scDetail?.scBornDate) }</Text>
                    </Col>
                  </Row>
                  <Row justify='space-between' className='mb-1'>
                    <Col flex='auto'>
                      {t('scDetailScNumber')}
                    </Col>
                    <Col>
                      <Text strong>{ scDetail?.scNumber }</Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} md={12} className='pl-2'>
                  <ScDpaList dpa={scDetail?.dpa || null} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <Card className='border-radius'>
          <Title level={3}><UsergroupAddOutlined /> {t('scFamily')}</Title>
          <Row className='mt-1'>
            <Col span={24}>
              <Table pagination={false} dataSource={scDetail?.family} columns={columns} rowKey='famType' />
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <Card className='border-radius'>
          <Title level={3}><CameraOutlined /> Año fiscal actual</Title>
          <Row className='mt-1' gutter={[30,30]}>
            <Col span={24} md={12}>
              <Title level={4}>{t('scPhotoActualSc')}</Title>
              <ImageWithAuth src={scDetail?.scChildPhotoActual || ''} scId={scDetail?.scId || 0} />
            </Col>
            <Col span={24} md={12}>
              <Title level={4}>{t('scPhotoActualFamily')}</Title>
              <ImageWithAuth src={scDetail?.scFamilyPhotoActual || ''} scId={scDetail?.scId || 0} viewMetadata={true} />
            </Col>
            <Col span={24} md={12}>
              <Title level={4}>{t('scConsentFormPhotoActual')}</Title>
              <ImageWithAuth src={scDetail?.scConsentFormPhotoActual || ''} scId={scDetail?.scId || 0} />
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <Card className='border-radius'>
          <Title level={3}><CameraOutlined /> Año fiscal anterior</Title>
          <Row className='mt-1' gutter={[30,30]}>
            <Col span={24} md={12}>
              <Title level={4}>{t('scPhotoLastYearSc')}</Title>
              <ImageWithAuth src={scDetail?.scChildPhoto || ''} scId={scDetail?.scId || 0} />
            </Col>
            <Col span={24} md={12}>
              <Title level={4}>{t('scPhotoLastYearFamily')}</Title>
              <ImageWithAuth src={scDetail?.scFamilyPhoto || ''} scId={scDetail?.scId || 0} xmlDataScFamilyPhoto={scDetail?.metaDataScFamilyPhoto} />
            </Col>
          </Row>
        </Card>
      </Col>

    </Row>
  );
}

export default ScDetail;
