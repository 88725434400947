import {Button, Col, Row, Typography, Grid} from "antd";
import Welcome from '../../assets/images/welcome.png';
import Smp from '../../assets/images/logo.svg';
import useFetch from "../../hooks/useFetch";
import {FetchApiInterface} from "../../interfaces/FetchApi.interface";
import {AUTH_SCOPES, AUTH_TOKEN, HOST_URL, REFRESH_TOKEN, USER_INFO} from "../../config/config";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import useSetLanguage from "../../hooks/useSetLanguage";
import useMessageUser from "../../hooks/useMessageUser";
import GlobalContex from "../../Store/GlobalContext";

const currentYear = new Date().getFullYear();
const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const urlParams = new URLSearchParams(window.location.search);
const code = urlParams.get('code');

function LoginPage() {
  const screen = useBreakpoint();
  const { fetchApi } = useFetch();
  const [loading, setLogin] = useState(false);
  const navigate = useNavigate();
  const {getLanguage, loadLanguage} = useSetLanguage();
  const { showMessage }  = useMessageUser();
  const { user: { setAuth } } = useContext(GlobalContex);

  useEffect(() => {
    if (localStorage.getItem(AUTH_TOKEN)) {
      navigate('/home');
      return;
    }
    if (code && !loading) {
      checkSession(code);
      return;
    }
  }, []);

  const checkSession = async (code: any) => {
    setLogin(true);
    const dateSend: FetchApiInterface = {
      url: '/auth/login_okta',
      method: 'POST',
      data: {
        code
      }
    };
    try {
      const { data } = await fetchApi(dateSend);
      setLogin(false);
      if (data?.status === 'success') {
        const roles = data.data?.roles || ["ROLE_COUNTRY_ADMIN"];
        localStorage.setItem(AUTH_TOKEN, data.data?.token);
        localStorage.setItem(AUTH_SCOPES, JSON.stringify(data.data?.scope));
        localStorage.setItem(REFRESH_TOKEN, data.data?.refreshToken);
        localStorage.setItem(USER_INFO, JSON.stringify({
          user: data.data?.data,
          roles: roles,
        }));
        setAuth({
          user: data.data?.data,
          roles: roles,
        });
        localStorage.setItem("oktaTokenId", data.data?.oktaTokenId);
        getLanguage(data.data?.data?.language?.lanName);
        if (!loadLanguage) {
          if (roles[0] === 'ROLE_SYSADMIN') {
            navigate('/users');
          } else {
            navigate('/home');
          }
        }
        return;
      }
    } catch (reason) {
      // @ts-ignore
      showMessage(reason?.message, 'error');
      setLogin(false);
      localStorage.clear();
      navigate('/authorization-code');
    }
  }

  const openOkta = () => {
    setLogin(true);
    // dev
    // window.open(`https://plan-international.oktapreview.com/oauth2/default/v1/authorize?client_id=0oa4m1spjdSdEazUl0x7&response_type=code&scope=openid&redirect_uri=${HOST_URL}&state=state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb601`, '_self');

    // prod
    window.open(`https://plan-international.okta-emea.com/oauth2/v1/authorize?client_id=0oa9z2nfgch2nWFHN0i7&response_type=code&scope=openid&redirect_uri=https://abc.plan-roa.org/authorization-code/callback&state=HtOCo48cBhl9W5JLlL7pqnquPHDiyp9xYgP3y1rx`, '_self');

    // training
    // window.open(`https://plan-international.oktapreview.com/oauth2/default/v1/authorize?client_id=0oa4m1spjdSdEazUl0x7&response_type=code&scope=openid&redirect_uri=${HOST_URL}&state=state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb601`, '_self');
  }

  return(
    <Row>
      <Col span={24}>
        <Row justify='center'>
          <Col span={22} sm={18} md={15}>
            <Row justify='center' className='h-100'>
              <Col span={24} className='center-center py-2'>
                <img className='logo-smp' src={Smp} alt="SMP"/>
              </Col>
              <Col span={24} md={16} lg={14} xxl={12}>
                <Title level={2}>Iniciar sesión</Title>
                <Paragraph>Ingresa tu correo electrónico y contraseña para iniciar sesión!</Paragraph>
                <Button block onClick={openOkta} loading={loading}>Login okta</Button>
              </Col>
              <Col span={24} className='center-center py-1'>
                © {currentYear} PLAN INTERNACIONAL
              </Col>
            </Row>
          </Col>
          {
            (screen.lg) && (
              <Col span={24} sm={6} md={9} style={{ overflow: 'hidden' }} className='text-right'>
                <img className='login-img' src={Welcome} alt="WELCOME"/>
              </Col>
            )
          }
        </Row>
      </Col>
    </Row>
  );
}
export default LoginPage;
