import {Col, Row, Typography} from "antd";
import ImportToDo from "./ImportToDo";
import ScActive from "./ScActive";
import SCUChildData from "./SCUChildData";
import ScCancell from "./ScCancell";
import {useTranslation} from "react-i18next";
import {getScopes} from "../../helperComms";

const {Title, Paragraph} = Typography;

const ImportModule = () => {
  const { t } = useTranslation();
  const scopes = getScopes();
  return(
    <Row>
      <Col span={24} className='p-1'>
        <Paragraph className='m-0 text-gray'>{t('user')}</Paragraph>
        <Title className='m-0 mb-2' level={4}>{t('importModule')}</Title>
        <Row gutter={[20, 20]}>
          {
            scopes.includes('IMP_ACTIVE') &&
              <Col span={24} lg={12}>
                  <ScActive />
              </Col>
          }
          {
            scopes.includes('IMP_INACTIVE') &&
              <Col span={24} lg={12}>
                  <ScCancell />
              </Col>
          }
          {
            scopes.includes('IMP_TODO') &&
              <Col span={24} lg={12}>
                  <ImportToDo />
              </Col>
          }
          {
            scopes.includes('IMP_CHILD') &&
              <Col span={24} lg={12}>
                  <SCUChildData />
              </Col>
          }
          {/*<Col span={24} lg={12}>
            <FamilyCore />
          </Col>*/}
        </Row>
      </Col>
    </Row>
  );
}
export default ImportModule;
