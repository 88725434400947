import {Button, Col, Form, message, Row, Space, Spin, Typography} from "antd";
import {GlobalCard} from "../Components/GlobalCard";
import RegionItem from "../Components/Catalog/RegionItem";
import {useContext, useEffect, useState} from "react";
import useFetch from "../../hooks/useFetch";
import {FetchApiInterface} from "../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../interfaces/Response.interface";
import {ArrowLeftOutlined, CloudDownloadOutlined, CloudUploadOutlined} from "@ant-design/icons";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {DpaInterface} from "../../interfaces/DpaInterface";
import ButtonUpload from "../Components/ButtonUpload";
import useTransformFormData from "../../hooks/useTransformFormData";
import {useTranslation} from "react-i18next";
import useMessageUser from "../../hooks/useMessageUser";
import {hasAccess} from "../../utils";
import GlobalContex from "../../Store/GlobalContext";


function Catalog() {

  const { md } = useBreakpoint();
  const { transformFormData } = useTransformFormData();
  const { showMessage }  = useMessageUser();
  const { user: { auth } } = useContext(GlobalContex);
  const [showBtnCountry, setShowBtnCountry] = useState(0);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const [dataRegisters, setDataRegister] = useState<Array<DpaInterface>>( []);
  const [totalRegister, setTotalRegister] = useState<number>( 0);
  const { fetchApi, loading, downloadFile } = useFetch();
  const { t } = useTranslation();
  const [currentView, setCurrentView] = useState('list');
  const [ form ] = Form.useForm();

  useEffect(() => {
    getRegisters();
    getTotalRegisters();
  }, []);

  const breadcrumbs = [
    {
      children: t('dpaTitle')
    }
  ];

  const getTotalRegisters = () => {
    const dataSend: FetchApiInterface = {
      url: '/catalogs/dpa/count_dpa',
      method: 'POST'
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          setTotalRegister(data.data);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    );
  }

  const getRegisters = () => {
    // Revisamos el nivel del usuario para mostrar los datos
    let data: any = {};
    if (auth?.roles?.includes('ROLE_SYSADMIN') || auth?.roles?.includes('ROLE_GLOBAL_ADMIN')) {
      data.dpaType = "Region";
    } else if (auth?.roles?.includes('ROLE_REGION_ADMIN')) {
      data.dpaFatherId = auth?.user?.dpa?.dpaIdRegion;
    } else {
      data.dpaFatherId = auth?.user?.dpa?.dpaIdCountry;
      setShowBtnCountry(auth?.user?.dpa?.dpaIdCountry);
    }

    const dataSend: FetchApiInterface = {
      url: '/catalogs/dpa/get_dpa',
      method: 'POST',
      data
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          setDataRegister(data.data);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const onFinish = (values: any) => {
    const formData = transformFormData(values);
    const dataSend: FetchApiInterface = {
      url: '/catalogs/dpa/save_dpa',
      data: formData,
      formData: true
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          form.resetFields();
          setCurrentView('list');
          setDataRegister([]);
          getRegisters();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const downloadCurrentTemplate = () => {
    setLoadingExcel(true);
    message.info(t('processDownloadLocation'));
    downloadFile(`catalogs/dpa/donwload_dpa/${showBtnCountry}`, {}, 'get').then(
        (response: any) => {
          if (response.status === 200 ) {
            const binaryData  = [];
            const filename    = `country_location.xlsx`;

            const file = new File([response.data], filename);

            binaryData.push(file);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: response.data.type }));
            downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
          } else {
            message.error(t('processDownloadLocationError'));
          }
        }
    ).catch(e => {
      message.error(t('processDownloadLocationError'));
    }).finally(() => {
      setLoadingExcel(false);
    });
  }

  return(
    <Row justify='center' className='py-2'>
      <Col span={23}>
        <GlobalCard breadcrumb={breadcrumbs}>
          {
            (currentView === 'list') ? (
              <Row gutter={[0, 20]} justify="space-between" className='mb-2'>
                <Col flex="auto">
                  <Typography.Title level={4}>{t('dpaTitle')}</Typography.Title>
                  <Typography.Paragraph>{t('dpaTotals')}: { totalRegister }</Typography.Paragraph>
                </Col>
                <Col>
                  <Space>
                    {
                        (hasAccess(['ROLE_GUEST'])) && (
                            <Space>
                              <Button size="large" type="primary" className="btn-border-radius" onClick={() => setCurrentView('upload')}>
                                <CloudUploadOutlined />{ (!md)? null : ` ${t('dpaUploadButton')}` }
                              </Button>
                            </Space>
                        )
                    }
                    {
                        (showBtnCountry && hasAccess(['ROLE_COUNTRY_ADMIN'])) && (
                            <Space>
                              <Button size="large" type="primary" className="btn-border-radius" loading={loadingExcel} onClick={downloadCurrentTemplate}>
                                <CloudDownloadOutlined />
                              </Button>
                            </Space>
                        )
                    }
                  </Space>

                </Col>
                <Col span={24}>
                  <Spin spinning={loading}>
                    {
                      dataRegisters.map((value, index) => (
                        <RegionItem data={value} key={index}/>
                      ))
                    }
                  </Spin>
                </Col>
              </Row>
            ) : (
              <Spin spinning={loading} tip="Loading...">
                <Row gutter={[0, 20]} justify="space-between" className='mb-2'>
                  <Col flex="auto">
                    <Typography.Title level={4}>{t('dpaTitleUpload')}</Typography.Title>
                  </Col>
                  <Col>
                    <Button size="large" type="dashed" danger className="btn-border-radius" onClick={() => setCurrentView('list')}>
                      <ArrowLeftOutlined />{ (!md)? null : ` ${t('dpaFormBackButton')}` }
                    </Button>
                  </Col>
                  <Col span={24}>
                    <Form form={form} layout="vertical" className="mt-1" onFinish={onFinish}>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            label={t('dpaInputTitleForm')}
                            name="file"
                            rules={[{ required: true, message: t('codeRequired') }]}
                            valuePropName='file'
                          >
                            <ButtonUpload name={t('dpaUploadButtonForm')} form={form} fieldName='file' fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                          </Form.Item>
                        </Col>
                        <Col span={24} className="text-center mt-1">
                          <Button size="large" type="primary" htmlType='submit' className="btn-border-radius" loading={loading}>
                            { t('dpaFormSave') }
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Spin>
            )
          }
        </GlobalCard>
      </Col>
    </Row>
  );
}

export default Catalog;
