import {
  Badge,
  Button, Checkbox,
  Col, DatePicker, Dropdown,
  Form,
  Input, Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography
} from 'antd';
import type { TableRowSelection } from 'antd/es/table/interface';
import {
  EllipsisOutlined,
  FileExcelOutlined,
  SyncOutlined
} from '@ant-design/icons';
import {useEffect, useState} from "react";
import {FetchApiInterface} from "../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../interfaces/Response.interface";
import useFetch from "../../../../hooks/useFetch";
import useMessageUser from "../../../../hooks/useMessageUser";
import {IMonitoring} from "../models";
import {useTranslation} from "react-i18next";
import DpaSelected from "../../../Components/DpaSelected";
import moment from "moment/moment";
import useExport from "../../../../hooks/comms/useExport";
import useCommunityWorker from "../hooks/useCommunityWorker";
import {getScopes} from "../helperComms";
const { Search } = Input;
const { Option } = Select;
const { Paragraph, Title } = Typography;
const { RangePicker } = DatePicker;


function TableCreateXMLSCU() {

  const { t } = useTranslation();
  const scopes = getScopes();
  const initialColumns = [
    {
      title: t('scNumber'),
      dataIndex: 'cinScNumber',
      key: 'cinScNumber',
      visible: true,
      fixed: 'left',
    },
    {
      title: t('scName'),
      dataIndex: 'cinScName',
      key: 'cinScName',
      visible: true,
    },
    {
      title: t('bornDate'),
      dataIndex: 'cinScBornDate',
      key: 'cinScBornDate',
      visible: true,
    },
    {
      title: t('gender'),
      dataIndex: 'cinScGenre',
      key: 'cinScGenre',
      visible: true,
    },
    {
      title: t('dpaId'),
      dataIndex: 'dpaId',
      key: 'dpaId',
      visible: true,
    },
    {
      title: t('fiscalYear'),
      dataIndex: 'cinFiscalYear',
      key: 'cinFiscalYear',
      visible: true,
    },
    {
      title: t('interviewedPerson'),
      dataIndex: 'cinPersonaEntrevistada',
      key: 'cinPersonaEntrevistada',
      visible: true,
    },
    {
      title: t('interviewDate'),
      dataIndex: 'cinFechaEntrevista',
      key: 'cinFechaEntrevista',
      visible: true,
      render: (item: string) => `${item ? moment(item).format('DD-MM-YYYY') : ''}`,
    },
    {
      title: t('communityName'),
      dataIndex: 'cinNombreComunidad',
      key: 'cinNombreComunidad',
      visible: true,
    },
    {
      title: t('communityCode'),
      dataIndex: 'cinCodigoComunidad',
      key: 'cinCodigoComunidad',
      visible: false,
    },
    {
      title: t('interviewName'),
      dataIndex: 'cinNombreEntrevista',
      key: 'cinNombreEntrevista',
      visible: false,
    },
    {
      title: t('sponsor'),
      dataIndex: 'cinPatrocinador',
      key: 'cinPatrocinador',
      visible: true,
    },
    {
      title: t('sponsorNumber'),
      dataIndex: 'cinNumeroPatrocinador',
      key: 'cinNumeroPatrocinador',
      visible: true,
    },
    {
      title: t('nationalOrganization'),
      dataIndex: 'cinOrganizacionNacional',
      key: 'cinOrganizacionNacional',
      visible: false,
    },
    {
      title: t('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      visible: false,
    },
    {
      title: 'Q. 1_1',
      dataIndex: 'opt_Q_1_1',
      key: 'opt_Q_1_1',
      visible: false,
    },
    {
      title: 'Q. 1_8',
      dataIndex: 'q_1_8',
      key: 'q_1_8',
      visible: false,
    },
    {
      title: 'Q. 1_10',
      dataIndex: 'q_1_10',
      key: 'q_1_10',
      visible: false,
    },
    {
      title: 'Q. 1_4',
      dataIndex: 'q_1_4',
      key: 'q_1_4',
      visible: false,
    },
    {
      title: 'Q. 1_15',
      dataIndex: 'q_1_15',
      key: 'q_1_15',
      visible: false,
    },
    {
      title: 'Q. 2_1',
      dataIndex: 'q_2_1',
      key: 'q_2_1',
      visible: false,
    },
    {
      title: 'Q. 2_2',
      dataIndex: 'q_2_2',
      key: 'q_2_2',
      visible: false,
    },
    {
      title: 'Q. 2_3',
      dataIndex: 'q_2_3',
      key: 'q_2_3',
      visible: false,
    },
    {
      title: 'Q. 2_4',
      dataIndex: 'q_2_4',
      key: 'q_2_4',
      visible: false,
    },
    {
      title: 'Q. 2_5',
      dataIndex: 'q_2_5',
      key: 'q_2_5',
      visible: false,
    },
    {
      title: 'Q. 3_1',
      dataIndex: 'q_3_1',
      key: 'q_3_1',
      visible: false,
    },
    {
      title: 'Q. 3_10',
      dataIndex: 'q_3_10',
      key: 'q_3_10',
      visible: false,
    },
    {
      title: 'Q. 4_1',
      dataIndex: 'q_4_1',
      key: 'q_4_1',
      visible: false,
    },
    {
      title: 'Q. 4_2',
      dataIndex: 'q_4_2',
      key: 'q_4_2',
      visible: false,
    },
    {
      title: 'Q. 4_3',
      dataIndex: 'q_4_3',
      key: 'q_4_3',
      visible: false,
    },
    {
      title: 'Q. 4_4',
      dataIndex: 'q_4_4',
      key: 'q_4_4',
      visible: false,
    },
    {
      title: 'Q. 4_5',
      dataIndex: 'q_4_5',
      key: 'q_4_5',
      visible: false,
    },
    {
      title: 'Q. 4_6',
      dataIndex: 'q_4_6',
      key: 'q_4_6',
      visible: false,
    },
    {
      title: 'Q. 4_7',
      dataIndex: 'q_4_7',
      key: 'q_4_7',
      visible: false,
    },
    {
      title: 'Q. 4_8',
      dataIndex: 'q_4_8',
      key: 'q_4_8',
      visible: false,
    },
    {
      title: 'Q. 4_9',
      dataIndex: 'q_4_9',
      key: 'q_4_9',
      visible: false,
    },
    {
      title: 'Q. 4_10',
      dataIndex: 'q_4_10',
      key: 'q_4_10',
      visible: false,
    },
    {
      title: 'Q. 4_13',
      dataIndex: 'q_4_13',
      key: 'q_4_13',
      visible: false,
    },
    {
      title: 'Q. 5_1',
      dataIndex: 'q_5_1',
      key: 'q_5_1',
      visible: false,
    },
    {
      title: 'Q. 5_2',
      dataIndex: 'q_5_2',
      key: 'q_5_2',
      visible: false,
    },
    {
      title: 'Q. 5_3',
      dataIndex: 'q_5_3',
      key: 'q_5_3',
      visible: false,
    },
    {
      title: 'Q. 5_4',
      dataIndex: 'q_5_4',
      key: 'q_5_4',
      visible: false,
    },
    {
      title: 'Q. 5_5',
      dataIndex: 'q_5_5',
      key: 'q_5_5',
      visible: false,
    },
    {
      title: 'Q. 5_6',
      dataIndex: 'q_5_6',
      key: 'q_5_6',
      visible: false,
    },
    {
      title: 'Q. 5_7',
      dataIndex: 'q_5_7',
      key: 'q_5_7',
      visible: false,
    },
    {
      title: 'Q. 6_1',
      dataIndex: 'q_6_1',
      key: 'q_6_1',
      visible: false,
    },
    {
      title: 'Q. 6_2',
      dataIndex: 'q_6_2',
      key: 'q_6_2',
      visible: false,
    },
    {
      title: 'Q. 6_3',
      dataIndex: 'q_6_3',
      key: 'q_6_3',
      visible: false,
    },
    {
      title: 'Q. 6_4',
      dataIndex: 'q_6_4',
      key: 'q_6_4',
      visible: false,
    },
    {
      title: 'Q. 6_5',
      dataIndex: 'q_6_5',
      key: 'q_6_5',
      visible: false,
    },
    {
      title: 'Q. 6_6',
      dataIndex: 'q_6_6',
      key: 'q_6_6',
      visible: false,
    },
    {
      title: 'Q. 6_7',
      dataIndex: 'q_6_7',
      key: 'q_6_7',
      visible: false,
    },
    {
      title: 'Q. 7_1',
      dataIndex: 'q_7_1',
      key: 'q_7_1',
      visible: false,
    },
    {
      title: 'Q. 7_2',
      dataIndex: 'q_7_2',
      key: 'q_7_2',
      visible: false,
    },
    {
      title: 'Q. 8a_1',
      dataIndex: 'q_8a_1',
      key: 'q_8a_1',
      visible: false,
    },
    {
      title: 'Q. 8a_2',
      dataIndex: 'q_8a_2',
      key: 'q_8a_2',
      visible: false,
    },
    {
      title: 'Q. 8a_4_1',
      dataIndex: 'q_8a_4_1',
      key: 'q_8a_4_1',
      visible: false,
    },
    {
      title: 'Q. 8a_4_2',
      dataIndex: 'q_8a_4_2',
      key: 'q_8a_4_2',
      visible: false,
    },
    {
      title: 'Q. 8a_4_3',
      dataIndex: 'q_8a_4_3',
      key: 'q_8a_4_3',
      visible: false,
    },
    {
      title: 'Q. 8a_4_4',
      dataIndex: 'q_8a_4_4',
      key: 'q_8a_4_4',
      visible: false,
    },
    {
      title: 'Q. 8a_4_5',
      dataIndex: 'q_8a_4_5',
      key: 'q_8a_4_5',
      visible: false,
    },
    {
      title: 'Q. 8a_4_6',
      dataIndex: 'q_8a_4_6',
      key: 'q_8a_4_6',
      visible: false,
    },
    {
      title: 'Q. 8a_4_7',
      dataIndex: 'q_8a_4_7',
      key: 'q_8a_4_7',
      visible: false,
    },
    {
      title: 'Q. 8a_5_1',
      dataIndex: 'q_8a_5_1',
      key: 'q_8a_5_1',
      visible: false,
    },
    {
      title: 'Q. 8a_5_2',
      dataIndex: 'q_8a_5_2',
      key: 'q_8a_5_2',
      visible: false,
    },
    {
      title: 'Q. 8a_5_3',
      dataIndex: 'q_8a_5_3',
      key: 'q_8a_5_3',
      visible: false,
    },
    {
      title: 'Q. 8a_5_4',
      dataIndex: 'q_8a_5_4',
      key: 'q_8a_5_4',
      visible: false,
    },
    {
      title: 'Q. 8a_6_1',
      dataIndex: 'q_8a_6_1',
      key: 'q_8a_6_1',
      visible: false,
    },
    {
      title: 'Q. 8a_6_2',
      dataIndex: 'q_8a_6_2',
      key: 'q_8a_6_2',
      visible: false,
    },
    {
      title: 'Q. 8a_6_3',
      dataIndex: 'q_8a_6_3',
      key: 'q_8a_6_3',
      visible: false,
    },
    {
      title: 'Q. 8a_6_4',
      dataIndex: 'q_8a_6_4',
      key: 'q_8a_6_4',
      visible: false,
    },
    {
      title: 'Q. 8a_6_5',
      dataIndex: 'q_8a_6_5',
      key: 'q_8a_6_5',
      visible: false,
    },
    {
      title: 'Q. 8a_6_6',
      dataIndex: 'q_8a_6_6',
      key: 'q_8a_6_6',
      visible: false,
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataSource, setDataSource] = useState<Array<IMonitoring>>([]);
  const [columns, setColumns] = useState<Array<any>>(initialColumns);
  const [filterApply, setFilterApply] = useState<any>({});
  const { fetchApi, loading } = useFetch();
  const { exportZIP, downloading } = useExport();
  const [openModal, setOpenModal] = useState(false);
  const [dataFilters, setDataFilters] = useState<any>({});
  const [searchText, setSearchText] = useState<string>('');
  const [form] = Form.useForm();
  const {communityWorkers} = useCommunityWorker();
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 20,
    total: 0,
  });
  const { showMessage }  = useMessageUser();

  useEffect(() => {
    if (!searchText) {
      const newFilterApply = { ...dataFilters };
      delete newFilterApply.search;
      setDataFilters(newFilterApply);
    }
  }, [searchText]);

  useEffect(() => {
    getTabla();
  }, [dataFilters]);

  const getTabla = (page = 1, size = pagination.pageSize) => {
    const dateSend = {
      isAfiliated: true,
      cinSync: true,
      ...dataFilters,
    };
    const dataSend: FetchApiInterface = {
      url: `/comms/get_child_info?page=${page - 1}&size=${size}`,
      data: dateSend,
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setDataSource(dataResponse?.content || []);
          setPagination({
            current: data?.data?.pageable?.pageNumber + 1,
            pageSize: size,
            total: data?.data?.totalElements,
          });
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize: pageSize || prev.pageSize,
    }));
    getTabla(page, pageSize || pagination.pageSize);
  };

  const exportData = async () => {
    const transformedData = selectedRowKeys.map(key => ({ cinId: key }));

    await exportZIP('/comms/donwload_xml_scu', transformedData);
  }

  const cleanFilters = () => {
    setSearchText('');
    form.resetFields();
    setFilterApply({});
    setDataFilters({});
    setOpenModal(false);
  }

  const onFinish = (values: any) => {
    let filters: any = { };

    if (searchText) {
      filters.search = searchText;
    }

    if (values?.communityWorker) {
      // separar el CH2 - JOSÉ BENAVIDES por el -
      let cw = values?.communityWorker;
      cw = cw.split("-");
      filters.communityWorker = `${cw[cw.length - 1]}`.trim();
    }

    if (values?.dpa?.length > 0) {
      filters.dpas = values.dpa.map((dpaId: any) => {
        if (dpaId?.dpaId) {
          return { dpaId: dpaId?.dpaId };
        }
        return { dpaId };
      });
    }

    if (values?.genre) {
      filters.genre = values.genre;
    }

    if (values?.lastDays && values?.lastDays > 0) {
      filters.lastDays = values.lastDays;
    }
    if (values?.rangeInterviewDate) {
      const date1 = moment(values?.rangeInterviewDate[0]);
      const date2 = moment(values?.rangeInterviewDate[1]);
      filters.rangeInterviewDate = {
        value1: date1.format('YYYY-MM-DD'),
        value2: date2.format('YYYY-MM-DD'),
      };
    }

    setDataFilters(filters);
    setOpenModal(false);
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const handleSearch = (value: string) => {
    if (value) {
      setSearchText(value);
      setDataFilters((prevFilters: any) => ({ ...prevFilters, search: value }));
    }
  };

  // Codigo para filtrar las columnas
  // Filtra las columnas basándote en la visibilidad
  const visibleColumns = columns.filter(column => column.visible);

  // Menú para el Dropdown con Checkboxes para cada columna
  const menu = (
    <Menu>
      {columns.map(column => (
        <Menu.Item key={column.key}>
          <Checkbox
            checked={column.visible}
            onChange={e => handleVisibleChange(e, column.key)}
          >
            {column.title}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  // El Dropdown que muestra el menú
  const dropdown = (
    <Dropdown trigger={['click']} overlay={menu}>
      <Button type='primary' shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
  // Función para manejar el cambio en el estado de visibilidad de las columnas
  const handleVisibleChange = (e: any, columnName: any) => {
    const newColumns = columns.map(column => {
      if (column.key === columnName) {
        return { ...column, visible: e.target.checked };
      }
      return column;
    });
    setColumns(newColumns);
  };

  return (
    <Row justify='end' gutter={[20, 20]}>
      <Col span={24}>
        <Title level={5}>{t('updateHistory')}</Title>
      </Col>
      <Col span={24}>
        <Row justify='space-between' >
          <Col>
            <Space>
              <Badge dot={(Object.keys(dataFilters)?.length > 0)} >
                <Button type='primary' ghost icon={ <FileExcelOutlined /> } onClick={() => setOpenModal(true)}>
                  {t('filters')}
                </Button>
              </Badge>
              {
                (selectedRowKeys.length > 0 && scopes.includes('XML_SCU_DESCARGAR')) && (
                  <Button type='primary' onClick={exportData} icon={<FileExcelOutlined />} loading={downloading}>
                    {t('exportRecords')}
                  </Button>
                )
              }
            </Space>

            <Modal width={600} title={t('filters')} open={openModal} onCancel={() => setOpenModal(false)} footer={
              <Row justify='space-between'>
                <Col>
                  <Button shape='round' type='primary' danger ghost onClick={cleanFilters}>
                    {t('clearFilters')}
                  </Button>
                </Col>
                <Col>
                  <Space>
                    <Button shape='round' type='text' onClick={() => setOpenModal(false)}>
                      {t('cancel')}
                    </Button>
                    <Button shape='round' type='primary' onClick={() => form.submit() }>
                      {t('applyFilters')}
                    </Button>
                  </Space>
                </Col>
              </Row>
            }>
              <Row>
                <Col span={24}>
                  <Form layout='vertical' form={form} onFinish={onFinish}>
                    <Row gutter={[10, 0]}>
                      <Col span={24} md={12}>
                        <Form.Item name='genre' label={t('genre')}>
                          <Select allowClear>
                            <Option value='Male'>{t('male')}</Option>
                            <Option value='Female'>{t('female')}</Option>
                            <Option value=''>{t('all')}</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24} md={12}>
                        <Form.Item name='communityWorker' label={t('fdc')}>
                          <Select allowClear showSearch>
                            {
                              communityWorkers.map((worker: any, index) => (
                                <Option key={index} value={worker?.assCommunityWorker}>{worker?.assCommunityWorker}</Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Paragraph strong>{t('place')}</Paragraph>
                      </Col>
                      <Col span={24}>
                        <DpaSelected form={form} setFilterApply={setFilterApply} filterApply={filterApply} isRequired={false} />
                      </Col>
                      <Col span={24}>
                        <Form.Item name='rangeInterviewDate' label={t('interviewDate')}>
                          <RangePicker />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name='lastDays' label={t('showLast')}>
                          <Radio.Group defaultValue={0}>
                            <Radio.Button value={1}>{t('last24Hours')}</Radio.Button>
                            <Radio.Button value={7}>{t('last7Days')}</Radio.Button>
                            <Radio.Button value={30}>{t('last1Month')}</Radio.Button>
                            <Radio.Button value={90}>{t('last3Months')}</Radio.Button>
                            <Radio.Button value={365}>{t('last1Year')}</Radio.Button>
                            <Radio.Button value={0}>{t('all')}</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Modal>
          </Col>
          <Col>
            <Space>
              <Search placeholder={t('search')} onSearch={handleSearch} allowClear enterButton value={searchText} onChange={(e) => setSearchText(e.target.value) } />
              { dropdown }
              <Tooltip title={t('reloadList')}>
                <Button icon={<SyncOutlined />} loading={loading} onClick={() => getTabla(0)} />
              </Tooltip>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
            rowSelection={(scopes.includes('XML_SCU_DESCARGAR')) ? rowSelection : undefined}
            dataSource={dataSource}
            columns={visibleColumns}
            scroll={{ x: 2000 }}
            rowKey='cinId'
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              onShowSizeChange: handlePaginationChange,
            }}
        />
      </Col>
    </Row>
  );
}

export default TableCreateXMLSCU;
