import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space, Spin
} from "antd";
import {FetchApiInterface} from "../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../interfaces/Response.interface";
import {useEffect, useState} from "react";
import useFetch from "../../../../hooks/useFetch";
import useMessageUser from "../../../../hooks/useMessageUser";
import {useTranslation} from "react-i18next";
import {getScopes} from "../helperComms";

const { Option } = Select;

function DrawerProjectC2({ open, setOpenDrawer, item }: any) {

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [types, setTypes] = useState<any>([]);
  const [years, setYears] = useState<any>([]);
  const [loadProjects, setLoadProjects] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [questionProjects, setQuestionProjects] = useState<any>([]);
  const { fetchApi } = useFetch();
  const { showMessage }  = useMessageUser();
  const [checkedItems, setCheckedItems] = useState<any>({});

  useEffect(() => {
    getType();
    getFiscalYears();
  }, []);

  useEffect(() => {
    form.setFieldValue('place', `${item?.lvl8DpaName || item?.lvl7DpaName || item?.lvl6DpaName || item?.lvl5DpaName || item?.lvl4DpaName} (${item?.puDpaCode}-${item?.lvl4DpaCode}-${item?.lvl8DpaCode || item?.lvl7DpaCode || item?.lvl6DpaCode || item?.lvl5DpaCode || item?.lvl4DpaCode})`);
  },[item]);

  useEffect(() => {
    if (years) {
      form.setFieldValue('fyeId', years[0]?.fyeId);
    }
    if (open && years) {
      getProjectsFY();
    }
  }, [open, years]);

  const getType = () => {
    const dataSend: FetchApiInterface = {
      url: `/catalogs/tipo_financiamiento`,
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setTypes(dataResponse);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }
  const getFiscalYears = () => {
    const dataSend: FetchApiInterface = {
      url: `/catalogs/fiscal_year`,
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setYears(dataResponse);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }
  const getProjects = () => {
    setLoadProjects(true);
    const dataSend: FetchApiInterface = {
      url: `/catalogs/projects`,
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setCheckedItems({});
          form.resetFields(['questions']);
          setQuestionProjects(dataResponse);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    }).finally(() => {
      setLoadProjects(false);
    });
  }

  const getProjectsFY = () => {
    const yearId = form.getFieldValue('fyeId');
    if (!yearId) {
      return;
    }
    setLoadProjects(true);
    const dataSend: FetchApiInterface = {
      url: `/catalogs/dpa/get_dpa_projects`,
      data: {
        dpaId: item?.lvl8DpaId || item?.lvl7DpaId || item?.lvl6DpaId || item?.lvl5DpaId || item?.lvl4DpaId || item?.puDpaId || item?.countryDpaId,
        fyeId: yearId,
      }
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          if (!dataResponse) {
            form.resetFields(['tfiId']);
            getProjects();
            return;
          }
          // seteamos el tipoFinanciamiento
          form.setFieldValue('tfiId', dataResponse?.tipoFinanciamiento?.tfiId);

          const implementationLists = dataResponse?.implementationLists || [];
          const projectList: any[] = [];
          const checks: any = {};
          implementationLists.forEach((item: any) => {
            if (item?.iliStatus) {
              const proId = item?.projects?.proId;
              checks[proId] = true;
              form.setFieldsValue({
                questions: {
                  [proId]: {
                    [`proId_${proId}`]: item?.iliDescription,
                  },
                },
              });
            }
            projectList.push({
              ...item?.projects,
              initialValue: (item?.iliStatus) ? item?.iliDescription : '',
            });
          });
          setQuestionProjects(projectList);
          setCheckedItems(checks);
        }
      }
    ).catch((reason: any) => {
    }).finally(() => {
      setLoadProjects(false);
    });
  }

  const handleCheckboxChange = (proId: number, checked: boolean) => {
    setCheckedItems({
      ...checkedItems,
      [proId]: checked,
    });
    // Si el checkbox se desmarca, borrar el valor del input asociado
    if (!checked) {
      form.setFieldsValue({
        questions: {
          [proId]: {
            [`proId_${proId}`]: '',
          },
        },
      });
    }
  };

  const closeAndClear = () => {
    setOpenDrawer(false);
    clearForm();
  }

  const clearForm = () => {
    // Reiniciar solo los elementos del formulario relacionados con questionProjects
    const projectFields = questionProjects.map((item: any) => ['questions', item.proId, `proId_${item.proId}`]);

    form.resetFields(projectFields);
    setCheckedItems({});
    // setQuestionProjects([]);
  }

  const onFinish = (values: any) => {
    try {
      setLoadingSave(true);
      const questions = values['questions'] || [];
      const listProjects = questions?.map((item: any) => {
          // Verificar si el item es undefined o no existe
          if (!item) {
            return null;
          }

          const proIdKey = Object.keys(item)[0];
          if (proIdKey && item[proIdKey]) {
            const proId = parseInt(proIdKey.split('_')[1], 10);
            const iliDescription = item[proIdKey].trim() ? item[proIdKey] : "Nuevo registro";
            if (iliDescription && !!checkedItems[proId]) {
              return {
                proId: proId,
                iliDescription: iliDescription || "",
                status: true,
              };
            } else {
              return null;
            }
          } else {
            return null;
          }
        })
        .filter((item: any) => item !== null);

      if (listProjects.length === 0) {
        showMessage('errorListProjects', 'info');
        setLoadingSave(false);
        return;
      }

      const data = {
        tfiId: values?.tfiId,
        dpaId: item?.lvl8DpaId || item?.lvl7DpaId || item?.lvl6DpaId || item?.lvl5DpaId || item?.lvl4DpaId || item?.puDpaId || item?.countryDpaId,
        fyeId: values?.fyeId,
        listProjects: listProjects,
        projectCompleted: (listProjects.length > 0),
      };

      const dataSend: FetchApiInterface = {
        url: `/catalogs/dpa/save_project`,
        data: data,
      }
      fetchApi(dataSend).then(
        ({ data }: AxiosResponse<ResponseInterface>) => {
          if (data.status === 'success') {
            const dataResponse = data?.data;
            closeAndClear();
          } else {
            showMessage(data?.message, 'info');
          }
        }
      ).catch((reason: any) => {
        showMessage(reason?.message, 'error');
      }).finally(() => {
        setLoadingSave(false);
      });

    } catch (reason: any) {
      setLoadingSave(false);
      showMessage(reason?.message, 'error');
    }
  }

  const confirm = () => {
    const yearId = form.getFieldValue('fyeId');
    if (!yearId) {
      return;
    }
    const dataSend: FetchApiInterface = {
      url: `/catalogs/projects/delete_dpa_projects`,
      data: {
        dpaId: item?.lvl8DpaId || item?.lvl7DpaId || item?.lvl6DpaId || item?.lvl5DpaId || item?.lvl4DpaId || item?.puDpaId || item?.countryDpaId,
        fyeId: yearId,
      }
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          closeAndClear();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }
  const scopes = getScopes();

  return(
    <Modal open={open} title={t('implementedProject')} footer={null} onCancel={() => setOpenDrawer(false)} width={600}>
      <Row>
        <Col span={24}>
          <Form form={form} disabled={!scopes.includes('COMP_2_SAVE')} layout='vertical' onFinish={onFinish}>
            <Row gutter={[10, 0]}>
              <Col span={24}>
                <Form.Item className="top-label" label={t('place')} name='place'>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label={t('fiscalYear')}
                  name="fyeId"
                  rules={[{ required: true, message: t('codeRequired') }]}
                >
                  <Select allowClear onChange={getProjectsFY} >
                    {
                      years.map((item: any) => (
                        <Option key={item.fyeId} value={item.fyeId}>{ item.fyeName }</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label={t('fundingType')}
                  name="tfiId"
                  rules={[{ required: true, message: t('codeRequired') }]}
                >
                  <Select allowClear >
                    {
                      types.map((item: any) => (
                        <Option key={item.tfiId} value={item.tfiId}>{ t(item.tfiName) }</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Spin spinning={loadProjects}>
              <Row>
                {
                  (questionProjects.length > 0) && (
                    <Col span={24} className='text-right'>
                      <p><strong>{t('whichProjectImplemented')}</strong></p>
                    </Col>
                  )
                }
                <Col span={24}>
                  {questionProjects.map((item: any, index: number) => (
                    <Row key={index}>
                      <Col span={24} md={12}>
                        <Checkbox
                          onChange={e => handleCheckboxChange(item?.proId, e.target.checked)}
                          checked={!!checkedItems[item?.proId]}
                        >
                          {t(item?.proName ?? '')}
                        </Checkbox>
                      </Col>
                      <Col span={24} md={12}>
                        <Form.Item
                          name={['questions', item?.proId, `proId_${item?.proId}`]}
                          rules={[{ required: !!checkedItems[item?.proId], message: t('codeRequired') }]}
                          initialValue={item?.initialValue}
                        >
                          <Input disabled={!checkedItems[item?.proId]} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Spin>
            <Divider />
            <Row justify='space-between'>
              <Col>
                {
                  (scopes.includes('COMP_2_SAVE')) && (
                    <Button danger ghost shape='round' onClick={clearForm}>{t('clearFields')}</Button>
                  )
                }
              </Col>
              <Col>
                <Space>
                  <Button type='primary' ghost shape='round' onClick={closeAndClear}>{t('cancel')}</Button>
                  {
                    (scopes.includes('COMP_2_SAVE')) && (
                      <Button type='primary' htmlType='submit' shape='round' loading={loadingSave}>{t('save')}</Button>
                    )
                  }
                </Space>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}

export default DrawerProjectC2;
