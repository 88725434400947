import {Field} from "../interfaces/LanguageList.interface";

function useKeyValueTranslate() {

  const keyValueTranslate = (items: Array<Field>) => {
    let list = {};
    items.forEach((item) => {
      list = {
        ...list,
        [item.field]: item.value
      }
    });
    return list;
  }

  return { keyValueTranslate };
}

export default useKeyValueTranslate;
