import {createContext} from "react";

const GlobalContex = createContext<any>({
  user: {
    auth: null,
    setAuth: null,
  },
  navBar: {
    nav: null,
    setNav: null,
  },
  currentLanguage: {
    language: null,
    setLanguage: null,
  },
  updateListLanguages: {
    updateLis: null,
    setUpdateLis: null,
  }
});

export default GlobalContex;
