import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Radio,
  Row,
  Spin,
  Typography
} from "antd";
import {GlobalCard} from "../Components/GlobalCard";
import {useEffect, useState} from "react";
import useFetch from "../../hooks/useFetch";
import {FetchApiInterface} from "../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../interfaces/Response.interface";
import {ArrowLeftOutlined, FilterOutlined, DeleteOutlined, SyncOutlined} from "@ant-design/icons";
import TableCustom from "../Components/TableCustom";
import useProcesDataTable from "../../hooks/useProcesDataTable";
import {TableDataInterface} from "../../interfaces/TableData.interface";
import {dataInitial} from "../../config/dataConfig";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import useMessageUser from "../../hooks/useMessageUser";
import {useTranslation} from "react-i18next";
import Search from "antd/lib/input/Search";
import DpaSelected from "../Components/DpaSelected";
import {useNavigate} from "react-router-dom";
import DoubleScDetail from "../Components/Home/DoubleScDetail";
import {hasAccess} from "../../utils";

const { Title, Text } = Typography;

const dataConfig = [
  {
    key: 'dafId',
    title: 'ID',
    hiddenToColumn: true,
  },
  {
    key: 'scName',
    title: 'scTableName'
  },
  {
    key: 'scNumber',
    title: '# SC'
  },
  {
    key: 'scGenre',
    title: 'scTableGenre'
  },
  {
    key: 'scBornDate',
    title: 'scTableBornDate'
  },
];

const actions = {
  list: {
    key: "list",
    name: "SC's"
  },
  create: {
    key: "create",
    name: "Crear idioma",
    breadcrumb: [
      {
        children: "SC's",
        href: '/home',
      },
      {
        children: "Crear nuevo SC"
      }
    ]
  },
  detail: {
    key: "detail",
    name: "Sc detail",
    breadcrumb: [
      {
        children: "SC's",
        href: '/home',
      },
      {
        children: "SC detail"
      }
    ]
  },
}

function DoublePage() {

  const { md, lg } = useBreakpoint();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [dataRegisters, setDataRegister] = useState<TableDataInterface>(dataInitial);
  const [action, setAction] = useState<any>( actions.list);
  const [expanded, setExpanded] = useState<boolean>( true);
  const [scId, setScId] = useState<number>( 0);
  const [homeFilter, setHomeFilter] = useState<any>( { statusRegister: true });
  const { fetchApi, downloadFile, loading } = useFetch();
  const { processData } = useProcesDataTable();
  const { showMessage }  = useMessageUser();
  // filtros
  const [filterApply, setFilterApply] = useState<any>({});
  const [filters, setFilters] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    // solo el rol de ROLE_SYSADMIN no tiene acceso a esta pantalla
    if (hasAccess(['ROLE_SYSADMIN'])) {
      return;
    } else {
      navigate('/users');
    }
  }, []);

  useEffect(() => {
    if (lg) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [lg]);


  useEffect(() => {
    if (localStorage.getItem('filter-dp')) {
      const filters = JSON.parse(localStorage.getItem('filter-dp') as string);
      setHomeFilter(filters);
      setTimeout(() => {
        processFilter();
      }, 500);
    } else {
      clearFilter();
    }
  }, []);

  const getRegisters = (data: any = {}, page = 0) => {
    setFilters(data);
    const dataSend: FetchApiInterface = {
      url: `/members/doble_afiliados?page=${page}`,
      data
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          const dataProcess = processData(dataResponse?.content || [], dataConfig);
          setDataRegister({
            ...dataProcess,
            paginate: {
              size: dataResponse.size,
              totalElements: dataResponse.totalElements,
              totalPages: dataResponse.totalElements,
              pageNumber: dataResponse?.pageable?.pageNumber + 1
            },
            actions: [
              {
                action: showDetailSc,
                name: 'scTableViewDetail'
              }
            ]
          });
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const showDetailSc = (item: any) => {
    setScId(item.dafId);
    changeAction('detail');
  }

  const changeAction = (option: string) => {
    // @ts-ignore
    const actionItem = actions[option];
    setAction(actionItem);
  }

  const pageChange = (page: number) => {
    processFilter(page);
  }

  // Filtros
  const checkClearText = () => {
    const search  = form.getFieldValue('search');
    if (!Boolean(search)) {
      processFilter();
    }
  }

  const clearFilter = () => {
    localStorage.removeItem('filter-dp');
    form.resetFields();
    setHomeFilter({});
    setFilterApply({});
    processFilter();
  }
  const existKey = (values: any): boolean => {
    if (values?.region || values?.country || values?.pu || values?.level4 || values?.genre || values?.rangeAge) return true;

    return !!values?.rangeSync;
  }

  useEffect(() => {
    processFilter();
  }, [filterApply]);

  const processFilter = (page = 0) => {
    const values = form.getFieldsValue();
    console.log(values);
    if (existKey(values)) {
      localStorage.setItem('filter-dp', JSON.stringify(values));
    }
    const dataFilter: any = {};

    if (Boolean(values?.search)) {
      dataFilter.search = values.search;
    }
    if (Boolean(values?.genre)) {
      dataFilter.genre = values.genre;
    }
    if (values?.rangeAge?.[1] > 0) {
      dataFilter.rangeAge = {
        value1: values.rangeAge[0],
        value2: values.rangeAge[1],
      }
    }
    if (values?.rangeSync?.value1 && values?.rangeSync?.value2 ) {
      dataFilter.rangeSync = values.rangeSync;
    }

    if (filterApply?.dpas) {
      dataFilter.dpas = filterApply?.dpas || [];
    } else if (values?.dpa) {
      dataFilter.dpas = [
        {
          dpaId: filterApply.dpa,
        }
      ] || [];
    }
    getRegisters({
     ...dataFilter,
     statusRegister: true,
    }, page);
  }

  return(
    <Row>
      <Col span={24}>
        <Row justify='center' gutter={[20, 20]}>
          {
            (action.key === "list") && (
              <Col span={24} lg={6} xxl={5} className={ (lg) ? 'content-layout' : '' }>
                <Card className="h-100">
                  {
                    (!lg) && (
                      <Row justify="end">
                        <Col>
                          <Button icon={<FilterOutlined />} type='primary' ghost onClick={() => setExpanded(!expanded)}/>
                        </Col>
                      </Row>
                    )
                  }
                  {
                    (expanded) && (
                      <Form
                        form={form}
                        name="filterHomeForm"
                        autoComplete="off"
                        layout='vertical'
                        initialValues={homeFilter}
                      >
                        <Row>

                          <Col span={24}>
                            <Typography.Title level={4}>{ t('scSearch') }</Typography.Title>
                            <Form.Item
                              name="search"
                            >
                              <Search allowClear placeholder={t('scSearchPlaceholder')} onSearch={() => processFilter()} onChange={checkClearText}/>
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Row justify='space-between' align='middle'>
                              <Col>
                                <Typography.Title level={4}>{ t('scFilter') }</Typography.Title>
                              </Col>
                              <Col>
                                <Button size='small' type='link' icon={<DeleteOutlined />} onClick={clearFilter}>
                                  <Typography.Text underline>
                                    { t('scCleanFilter') }
                                  </Typography.Text>
                                </Button>
                              </Col>
                            </Row>
                          </Col>

                          <Col span={24} md={12} lg={24}>
                            <DpaSelected form={form} setFilterApply={setFilterApply} filterApply={filterApply} />
                          </Col>

                          <Col span={24}>
                            <Form.Item
                              label={<Title level={5}>{t('scGenre')}</Title>}
                              name="genre"
                            >
                              <Radio.Group onChange={() => processFilter()}>
                                <Row gutter={[10, 10]}>
                                  <Col span={24}>
                                    <Radio value="Male">{t('scGenreMale')}</Radio>
                                  </Col>
                                  <Col span={24}>
                                    <Radio value="Female">{t('scGenreFemale')}</Radio>
                                  </Col>
                                  <Col span={24}>
                                    <Radio value="">{t('scAllGenre')}</Radio>
                                  </Col>
                                </Row>
                              </Radio.Group>
                            </Form.Item>
                          </Col>

                        </Row>
                      </Form>
                    )
                  }
                </Card>
              </Col>
            )
          }
          <Col span={23} lg={18} xxl={19} className='py-2' style={{ paddingRight: (lg) ? '30px' : '' }}>
            <GlobalCard breadcrumb={action?.breadcrumb}>
              <Row gutter={[0, 20]} justify="space-between" className='mb-2'>
                <Col flex="auto">
                  <Typography.Title level={4}>SC’s </Typography.Title>
                </Col>
                <Col>
                  {
                    (action.key === "list") ? (
                      <Button size="large" type="primary" className="btn-border-radius" onClick={() => processFilter()}>
                        <SyncOutlined />
                      </Button>
                    ) : (
                      <Button size="large" type="dashed" danger className="btn-border-radius" onClick={() => changeAction('list')}>
                        <ArrowLeftOutlined />{ (!md)? null : ` ${t('return')}` }
                      </Button>
                    )
                  }
                </Col>
                <Col span={24}>
                  {
                    (action.key === "list") && (
                      <Row gutter={[20, 20]}>
                        <Col span={24}>
                          <Typography.Title level={5}>
                            {t('scTableResult')} ({ dataRegisters.paginate.totalElements })
                          </Typography.Title>
                        </Col>
                        {
                          (dataRegisters.dataSource.length > 0) ? (
                            <Col span={24}>
                              <Spin spinning={loading}>
                                <TableCustom dataTable={dataRegisters} getRegisters={getRegisters} onPageChange={pageChange} />
                              </Spin>
                            </Col>
                          ) : (
                            <Col span={24}>
                              <Empty/>
                            </Col>
                          )
                        }

                      </Row>
                    )
                  }
                  {
                    (action.key === "detail") && (
                      <DoubleScDetail scId={scId} />
                    )
                  }
                </Col>
              </Row>
            </GlobalCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default DoublePage;
