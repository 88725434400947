import {Breadcrumb, Card, Col, Row} from "antd";
import {ReactNode} from "react";
import {BreadcrumbItemProps} from "antd/lib/breadcrumb/BreadcrumbItem";
import {Link} from "react-router-dom";

export function GlobalCard({ children, breadcrumb }: { children: ReactNode, breadcrumb?: Array<BreadcrumbItemProps> }) {
  return (
    <Row>
      <Col span={24}>
        <Breadcrumb>
          
          {
            breadcrumb?.map((breard, index) => (
              <Breadcrumb.Item key={index}>{ breard.children }</Breadcrumb.Item>
            ))
          }
        </Breadcrumb>
      </Col>
      <Col span={24} className="mt-1">
        <Card className="border-radius shadow-sm p-1">
          { children }
        </Card>
      </Col>
    </Row>
  );
}
