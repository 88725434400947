import {
  Button,
  Card,
  Col,
  DatePicker, Empty,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Slider,
  Space, Spin,
  Typography
} from "antd";
import {GlobalCard} from "../Components/GlobalCard";
import {useEffect, useState} from "react";
import useFetch from "../../hooks/useFetch";
import {FetchApiInterface} from "../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../interfaces/Response.interface";
import {ArrowLeftOutlined, FilterOutlined, DeleteOutlined, CalendarOutlined, SyncOutlined} from "@ant-design/icons";
import TableCustom from "../Components/TableCustom";
import useProcesDataTable from "../../hooks/useProcesDataTable";
import {TableDataInterface} from "../../interfaces/TableData.interface";
import {dataInitial} from "../../config/dataConfig";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import ScForm from "../Components/Home/ScForm";
import UploadFileSc from "../Components/Home/UploadFileSc";
import ScDetail from "../Components/Home/ScDetail";
import {ScInterface} from "../../interfaces/Sc.interface";
import useMessageUser from "../../hooks/useMessageUser";
import {useTranslation} from "react-i18next";
import Search from "antd/lib/input/Search";
import moment from "moment/moment";
import DpaSelected from "../Components/DpaSelected";
import {useNavigate} from "react-router-dom";
import {hasAccess} from "../../utils";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const dataConfig = [
  {
    key: 'scId',
    title: 'ID',
    hiddenToColumn: true,
  },
  {
    key: 'updatedAt',
    title: 'updatedAt'
  },
  {
    key: 'scName',
    title: 'scTableName'
  },
  {
    key: 'scNumber',
    title: '# SC'
  },
  {
    key: 'scGenre',
    title: 'scTableGenre'
  },
  {
    key: 'scBornDate',
    title: 'scTableBornDate'
  },
  {
    key: 'pu',
    title: 'scTablePu'
  },
  {
    key: 'community',
    title: 'scTableLvl4'
  }
];

const actions = {
  list: {
    key: "list",
    name: "SC's"
  },
  create: {
    key: "create",
    name: "Crear idioma",
    breadcrumb: [
      {
        children: "SC's",
        href: '/home',
      },
      {
        children: "Crear nuevo SC"
      }
    ]
  },
  detail: {
    key: "detail",
    name: "Sc detail",
    breadcrumb: [
      {
        children: "SC's",
        href: '/home',
      },
      {
        children: "SC detail"
      }
    ]
  },
}

function Home() {

  const { md, lg } = useBreakpoint();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [dataRegisters, setDataRegister] = useState<TableDataInterface>(dataInitial);
  const [action, setAction] = useState<any>( actions.list);
  const [expanded, setExpanded] = useState<boolean>( true);
  const [loadZip, setLoadZip] = useState<boolean>( false);
  const [scId, setScId] = useState<number>( 0);
  const [homeFilter, setHomeFilter] = useState<any>( { statusRegister: true });
  const { fetchApi, downloadFile, loading } = useFetch();
  const { processData } = useProcesDataTable();
  const { showMessage }  = useMessageUser();
  // filtros
  const [selectPicker, setSelectPicker] = useState('selected');
  const [rangeSyncPicker, setRangeSyncPicker] = useState<any>();
  const [openModalDatePicker, setOpenModalDatePicker] = useState(false);
  const [rangeAge, setRangeAge] = useState<Array<number>>([0, 0]);
  const [filterApply, setFilterApply] = useState<any>({});
  const [filters, setFilters] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    // solo el rol de ROLE_SYSADMIN no tiene acceso a esta pantalla
    if (hasAccess(['ROLE_SYSADMIN'])) {
      return;
    } else {
      navigate('/users');
    }
  }, []);

  useEffect(() => {
    if (lg) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [lg]);


  useEffect(() => {
    if (localStorage.getItem('filter-home')) {
      const filters = JSON.parse(localStorage.getItem('filter-home') as string);
      if (filters?.rangeAge) {
        setRangeAge(filters.rangeAge);
      }
      if (filters?.rangeSync) {
        setRangeSyncPicker(filters.rangeSync);
      }
      setHomeFilter(filters);
      setTimeout(() => {
        processFilter();
      }, 500);
    } else {
      clearFilter();
    }
  }, []);

  const getRegisters = (data: any = {}, page = 0) => {
    setFilters(data);
    const dataSend: FetchApiInterface = {
      url: `/members/get_sc?page=${page}`,
      data
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          const dataProcess = processData(dataResponse?.content || [], dataConfig);
          setDataRegister({
            ...dataProcess,
            paginate: {
              size: dataResponse.size,
              totalElements: dataResponse.totalElements,
              totalPages: dataResponse.totalElements,
              pageNumber: dataResponse?.pageable?.pageNumber + 1
            },
            actions: [
              {
                action: showDetailSc,
                name: 'scTableViewDetail'
              }
            ]
          });
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const showDetailSc = (item: ScInterface) => {
    setScId(item.scId);
    changeAction('detail');
  }

  const changeAction = (option: string) => {
    // @ts-ignore
    const actionItem = actions[option];
    setAction(actionItem);
  }

  const pageChange = (page: number) => {
    getRegisters(filters, page);
  }

  // Filtros
  const checkClearText = () => {
    const search  = form.getFieldValue('search');
    if (!Boolean(search)) {
      processFilter();
    }
  }

  const clearFilter = () => {
    setRangeAge([0, 0]);
    localStorage.removeItem('filter-home');
    form.resetFields();
    setHomeFilter({});
    setFilterApply({});
    setSelectPicker('selected');
    setRangeSyncPicker(undefined);
    getRegisters({
      statusRegister: true,
    }, 0);
  }

  const changeRangeValue = (value: Array<number>) => {
    form.setFieldsValue({
      rangeAge: value
    });
    setRangeAge(value);
    processFilter();
  }

  const setDate = (option: string) => {
    setSelectPicker(option);
    const dateNowCalc = moment(new Date());
    const dateNow = moment(new Date());
    let rangeSync: any = {
      value1: '',
      value2: '',
    }
    switch (option) {
      case 'now':
        rangeSync.value1 = dateNow.format(dateFormat);
        rangeSync.value2 = dateNow.format(dateFormat);
        break;
      case 'yesterday':
        rangeSync.value1 = dateNowCalc.subtract(1, 'days').format(dateFormat);
        rangeSync.value2 = dateNow.format(dateFormat);
        break;
      case 'last-7':
        rangeSync.value1 = dateNowCalc.subtract(7, 'days').format(dateFormat);
        rangeSync.value2 = dateNow.format(dateFormat);
        break;
      case 'last-15':
        rangeSync.value1 = dateNowCalc.subtract(15, 'days').format(dateFormat);
        rangeSync.value2 = dateNow.format(dateFormat);
        break;
      case 'last-month':
        const yearNumber  = dateNowCalc.format('Y');
        const monthNumber = dateNowCalc.format('M');
        rangeSync.value1 = moment(`${yearNumber}-${monthNumber}-01`).format(dateFormat);
        rangeSync.value2 = dateNow.format(dateFormat);
        break;
      default:
        rangeSync = null;
        break;
    }
    setRangeSyncPicker(rangeSync);
    processFilter();
  }

  const selectDate = (dates: any) => {
    const datesFormat = {
      value1: dates[0].format(dateFormat),
      value2: dates[1].format(dateFormat),
    };
    form.setFieldValue('rangeSync', datesFormat);
    setRangeSyncPicker(datesFormat);
  }

  const applyDateRange = () => {
    form.setFieldValue('rangeSync', rangeSyncPicker);
    setOpenModalDatePicker(false);
    processFilter();
  }

  const cancelDateRange = () => {
    setRangeSyncPicker(null);
    form.setFieldValue('rangeSync', null);
    setSelectPicker('selected');
    setOpenModalDatePicker(false);
    processFilter();
  }

  const existKey = (values: any): boolean => {
    if (values?.region || values?.country || values?.pu || values?.level4 || values?.genre || values?.rangeAge) return true;

    return !!values?.rangeSync;
  }

  useEffect(() => {
    processFilter();
  }, [filterApply]);

  const processFilter = (page = 0) => {
    const values = form.getFieldsValue();
    if (existKey(values)) {
      localStorage.setItem('filter-home', JSON.stringify(values));
    }
    const dataFilter: any = {
      ...filters,
    };

    if (Boolean(values?.search)) {
      dataFilter.search = values.search;
    }
    if (Boolean(values?.genre)) {
      dataFilter.genre = values.genre;
    } else {
      delete dataFilter.genre;
    }
    if (values?.rangeAge?.[1] > 0) {
      dataFilter.rangeAge = {
        value1: values.rangeAge[0],
        value2: values.rangeAge[1],
      }
    }
    if (values?.rangeSync?.value1 && values?.rangeSync?.value2 ) {
      dataFilter.rangeSync = values.rangeSync;
    }

    if (filterApply?.dpas) {
      dataFilter.dpas = filterApply?.dpas || [];
    } else if (values?.dpa) {
      dataFilter.dpas = [
        {
          dpaId: filterApply.dpa,
        }
      ] || [];
    }
    getRegisters({
     ...dataFilter,
     statusRegister: true,
    }, page);
  }

  const downloadImages = () => {
    setLoadZip(true);
    downloadFile('/members/download_images', filters).then(
      (response: any) => {
        const dateNow = new Date().toISOString();
        if (response.status === 200) {
          // Crear un objeto URL temporal que apunte al Blob
          const url = URL.createObjectURL(response.data);

          // Crear un enlace en la página que apunte al URL
          const link = document.createElement('a');
          link.href = url;
          link.download = `${dateNow}_photos.zip`;
          document.body.appendChild(link);

          // Hacer clic en el enlace para descargar el archivo
          link.click();

          // Liberar el objeto URL temporal
          URL.revokeObjectURL(url);
        } else {
          showMessage('ExceptionNoFiles', 'info');
        }
      }
    ).catch(reason => {
      showMessage(reason?.message, 'error');
    }).finally(() => {
      setLoadZip(false);
    });
  }

  return(
    <Row>
      <Col span={24}>
        <Row justify='center' gutter={[20, 20]}>
          {
            (action.key === "list") && (
              <Col span={24} lg={6} xxl={5} className={ (lg) ? 'content-layout' : '' }>
                <Card className="h-100">
                  {
                    (!lg) && (
                      <Row justify="end">
                        <Col>
                          <Button icon={<FilterOutlined />} type='primary' ghost onClick={() => setExpanded(!expanded)}/>
                        </Col>
                      </Row>
                    )
                  }
                  {
                    (expanded) && (
                      <Form
                        form={form}
                        name="filterHomeForm"
                        autoComplete="off"
                        layout='vertical'
                        initialValues={homeFilter}
                      >
                        <Row>

                          <Col span={24}>
                            <Typography.Title level={4}>{ t('scSearch') }</Typography.Title>
                            <Form.Item
                              name="search"
                            >
                              <Search allowClear placeholder={t('scSearchPlaceholder')} onSearch={() => processFilter()} onChange={checkClearText}/>
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Row justify='space-between' align='middle'>
                              <Col>
                                <Typography.Title level={4}>{ t('scFilter') }</Typography.Title>
                              </Col>
                              <Col>
                                <Button size='small' type='link' icon={<DeleteOutlined />} onClick={clearFilter}>
                                  <Typography.Text underline>
                                    { t('scCleanFilter') }
                                  </Typography.Text>
                                </Button>
                              </Col>
                            </Row>
                          </Col>

                          <Col span={24} md={12} lg={24}>
                            <DpaSelected form={form} setFilterApply={setFilterApply} filterApply={filterApply} />
                          </Col>

                          <Col span={24}>
                            <Row>
                              <Col span={24}>
                                <Row justify='space-between'>
                                  <Col>
                                    <Title level={5}>{t('scAge')}</Title>
                                  </Col>
                                  {
                                    (rangeAge?.length > 0) && (
                                      <Col>
                                        <Text strong>{rangeAge[0]}-{rangeAge[1]} {t('scYears')}</Text>
                                      </Col>
                                    )
                                  }
                                </Row>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  className="m-0"
                                  name="rangeAge"
                                >
                                  <div className='container-indicator-slider'>
                                    <Text strong>0</Text>
                                    <Slider defaultValue={[rangeAge[0], rangeAge[1]]} min={0} max={18} className='w-100' range onAfterChange={changeRangeValue} />
                                    <Text strong>18</Text>
                                  </div>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>

                          <Col span={24}>
                            <Form.Item style={{ display: 'none' }} name='rangeSync'>
                              <Input />
                            </Form.Item>
                            <Title level={5}>{t('scSyncDate')}</Title>
                            <div onClick={() => setOpenModalDatePicker(true)} className='date-picker-modal-btn cursor-pointer'>
                              <Row justify='space-between'>
                                <Col>
                                  {
                                    (rangeSyncPicker?.value1) ? (
                                      <>
                                        { rangeSyncPicker?.value1 } { `- ${rangeSyncPicker?.value2 || ''}` }
                                      </>
                                    ) : <>{t('scSyncDatePlaceholder')}</>
                                  }
                                </Col>
                                <Col><CalendarOutlined /></Col>
                              </Row>
                            </div>
                            <Modal
                              cancelText={t('scSyncDateCancel')}
                              okText={t('scSyncDateApply')}
                              width={800} onCancel={cancelDateRange}
                              onOk={applyDateRange}
                              title={t('scSyncDate')}
                              open={openModalDatePicker}>
                              <Row>
                                <Col span={24} md={8}>
                                  <div className='container-list-datepicker'>
                                    <div className={(selectPicker === 'now')? 'item-date-active' : ''} onClick={() => setDate('now')}><Text strong>{t('scSyncDateToday')}</Text></div>
                                    <div className={(selectPicker === 'yesterday')? 'item-date-active' : ''} onClick={() => setDate('yesterday')}><Text strong>{t('scSyncDateYesterday')}</Text></div>
                                    <div className={(selectPicker === 'last-7')? 'item-date-active' : ''} onClick={() => setDate('last-7')}><Text strong>{t('scSyncDateLast7Days')}</Text></div>
                                    <div className={(selectPicker === 'last-15')? 'item-date-active' : ''} onClick={() => setDate('last-15')}><Text strong>{t('scSyncDateLast15Days')}</Text></div>
                                    <div className={(selectPicker === 'last-month')? 'item-date-active' : ''} onClick={() => setDate('last-month')}><Text strong>{t('scSyncDateLastMonth')}</Text></div>
                                    <div className={(selectPicker === 'selected')? 'item-date-active' : ''} onClick={() => setDate('selected')}><Text strong>{t('scSyncDateCustom')}</Text></div>
                                  </div>
                                </Col>
                                <Col span={24} md={16} className='center-center'>
                                  {
                                    (selectPicker === 'selected') ? (
                                      <RangePicker
                                        onChange={selectDate}
                                        format={dateFormat}
                                        size='large'
                                      />
                                    ) : (
                                      <Space style={{ fontSize: 20 }}>
                                        {t('scSyncDateFrom')} <strong className='color-primary'>{ rangeSyncPicker?.value1 }</strong> {t('scSyncDateTo')} <strong className='color-primary'>{ rangeSyncPicker?.value2 }</strong>
                                      </Space>
                                    )
                                  }
                                </Col>
                              </Row>
                            </Modal>
                          </Col>

                          <Col span={24}>
                            <Form.Item
                              label={<Title level={5}>{t('scGenre')}</Title>}
                              name="genre"
                            >
                              <Radio.Group onChange={() => processFilter()}>
                                <Row gutter={[10, 10]}>
                                  <Col span={24}>
                                    <Radio value="Male">{t('scGenreMale')}</Radio>
                                  </Col>
                                  <Col span={24}>
                                    <Radio value="Female">{t('scGenreFemale')}</Radio>
                                  </Col>
                                  <Col span={24}>
                                    <Radio value="">{t('scAllGenre')}</Radio>
                                  </Col>
                                </Row>
                              </Radio.Group>
                            </Form.Item>
                          </Col>

                        </Row>
                      </Form>
                    )
                  }
                </Card>
              </Col>
            )
          }
          <Col span={23} lg={18} xxl={19} className='py-2' style={{ paddingRight: (lg) ? '30px' : '' }}>
            <GlobalCard breadcrumb={action?.breadcrumb}>
              <Row gutter={[0, 20]} justify="space-between" className='mb-2'>
                <Col flex="auto">
                  <Typography.Title level={4}>SC’s </Typography.Title>
                </Col>
                <Col>
                  {
                    (action.key === "list") ? (
                      <Space>
                        {
                          (hasAccess(['ROLE_GUEST', 'ROLE_SYSADMIN'])) && (
                            <Button loading={loadZip} size="large" type="primary" className="btn-border-radius" onClick={downloadImages}>
                              {t('btnDownloadPhotos')}
                            </Button>
                          )
                        }

                        {
                          ( hasAccess(['ROLE_GUEST', 'ROLE_USER', 'ROLE_COUNTRY_PROCESS', 'ROLE_SYSADMIN'])) && (
                            <UploadFileSc />
                          )
                        }

                        <Button size="large" type="primary" className="btn-border-radius" onClick={() => processFilter()}>
                          <SyncOutlined />
                        </Button>
                      </Space>
                    ) : (
                      <Button size="large" type="dashed" danger className="btn-border-radius" onClick={() => changeAction('list')}>
                        <ArrowLeftOutlined />{ (!md)? null : ` ${t('return')}` }
                      </Button>
                    )
                  }
                </Col>
                <Col span={24}>
                  {
                    (action.key === "list") && (
                      <Row gutter={[20, 20]}>
                        <Col span={24}>
                          <Typography.Title level={5}>
                            {t('scTableResult')} ({ dataRegisters.paginate.totalElements })
                          </Typography.Title>
                        </Col>
                        {
                          (dataRegisters.dataSource.length > 0) ? (
                            <Col span={24}>
                              <Spin spinning={loading}>
                                <TableCustom dataTable={dataRegisters} getRegisters={getRegisters} onPageChange={pageChange} />
                              </Spin>
                            </Col>
                          ) : (
                            <Col span={24}>
                              <Empty/>
                            </Col>
                          )
                        }

                      </Row>
                    )
                  }
                  {
                    (action.key === "create") && (
                      <ScForm />
                    )
                  }
                  {
                    (action.key === "detail") && (
                      <ScDetail scId={scId} />
                    )
                  }
                </Col>
              </Row>
            </GlobalCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Home;
