import {Button, Card, Col, Popconfirm, Progress, Row, Space, Typography, Upload, UploadProps} from "antd";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FileOutlined,
  ImportOutlined
} from "@ant-design/icons";
import {useState} from "react";
import useFetch from "../../../../../hooks/useFetch";
import useMessageUser from "../../../../../hooks/useMessageUser";
import {FetchApiInterface} from "../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../interfaces/Response.interface";
import {useTranslation} from "react-i18next";
const {Title, Text, Paragraph} = Typography;
const { Dragger } = Upload;

const ScCancell = () => {

  const [fileList, setFileList] = useState<any[]>([]);
  const { fetchApi, loading }  = useFetch();
  const { showMessage }  = useMessageUser();
  const { t } = useTranslation();

  const props: UploadProps = {
    onRemove: file => {
      setFileList(fileList.filter(item => item.uid !== file.uid));
    },
    beforeUpload: file => {
      const isXML = file.name.endsWith('.csv');
      if (!isXML) {
        showMessage('codeExeptionExtensionFile', 'error');
        return false;
      }
      const newFile = {
        uid: file.uid,
        name: file.name,
        status: "normal",
        percent: 0,
        file: file,
      };
      setFileList([...fileList, newFile]);
      return false;
    },
    fileList: [],
  };

  const confirmDelete = (fileItem: any) => {
    setFileList(fileList.filter(file => file.uid !== fileItem.uid));
  };

  const renderItem = (file: any, index: number) => {
    return (
      <Row key={index} className='mb-1'>
        <Col span={24}>
          <Card>
            <Row align="middle" gutter={[10, 0]}>
              <Col>
                <FileOutlined />
              </Col>
              <Col flex={1}>
                <Row>
                  <Col span={24}>{file.name}</Col>
                  <Col span={24}>
                    <Progress percent={file?.percent} status={file?.status} />
                    { (file?.percent === 100 && file?.status !== 'done') && <Paragraph>
                      {
                        (file?.status === 'exception') ? (
                          `${file?.error}`
                        ) : (
                          t('processingFile')  // Reemplaza 'Procesando el archivo...'
                        )
                      }
                    </Paragraph> }
                  </Col>
                </Row>
              </Col>
              <Col>
                <Popconfirm
                  title={t('deleteFile')}  // Reemplaza 'Eliminar archivo'
                  onConfirm={() => confirmDelete(file)}
                  okText={t('yes')}  // Reemplaza 'Si'
                  cancelText={t('no')}  // Reemplaza 'No'
                >
                  <Button size='small' icon={<DeleteOutlined />} danger />
                </Popconfirm>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  };

  const onFinish = async () => {
    let url = '/comms/upload_sc_inactive';
    for (let i = 0; i < fileList.length; i++) {
      const fileToUpdate = fileList[i];
      // Establecer el estado de este archivo específico como 'active' y reiniciar el progreso
      setFileList(fileList.map(file =>
        file.uid === fileToUpdate.uid ? { ...file, status: 'active', percent: 0 } : file
      ));

      const formData = new FormData();
      formData.append('file', fileToUpdate?.file);

      const dataSend: FetchApiInterface = {
        url,
        data: formData,
        formData: true
      };

      try {
        // Simular el progreso del archivo
        for (let percent = 0; percent <= 100; percent++) {
          setFileList(fileList.map(file =>
            file.uid === fileToUpdate.uid ? { ...file, percent: percent } : file
          ));
          // Aquí deberías simular una espera, esto es solo un ejemplo
          await new Promise(resolve => setTimeout(resolve,  3));
        }

        const { data }: AxiosResponse<ResponseInterface> = await fetchApi(dataSend);

        if (data.status === 'success') {
          // Establecer el estado de este archivo específico como 'done' cuando la carga es exitosa
          setFileList(fileList.map(file =>
            file.uid === fileToUpdate.uid ? { ...file, status: 'done', percent: 100 } : file
          ));
          showMessage(data?.message, 'success');
        } else {
          showMessage(data?.message, 'info');
          // Si no es exitoso, puedes querer establecer un estado diferente aquí
        }
      } catch (reason: any) {
        // Establecer el estado de este archivo específico como 'exception' si hay un error
        setFileList(fileList.map(file =>
          file.uid === fileToUpdate.uid ? { ...file, status: 'exception', error: reason?.message, percent: 100 } : file
        ));
        showMessage(reason?.message, 'error');
      }
    }
  };

  return(
    <Card className='border-radius'>
      <Row>
        <Col span={24}>
          <Row justify='space-between'>
            <Col>
              <Title level={5}>{t('scCancel')}:</Title>
            </Col>
            <Col>
              <Button icon={<DownloadOutlined />}></Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Paragraph className='text-gray'>
            {t('uploadCancelQueryCsv')}
          </Paragraph>
        </Col>
        <Col span={24}>
          {
            fileList.map((file: File, index: number) => renderItem(file, index))
          }
        </Col>
        <Col span={24} className='mt-1'>
          <div>
            <Title level={5}>SC_Cancell_gridView.csv:</Title>
          </div>
          <div>
            <Dragger {...props}>
              <div className='p-2'>
                <Space>
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined style={{ fontSize: '15px' }} /> {t('dragAndDrop')} <Text strong underline className='color-primary'>{t('selectFile')}</Text>
                  </p>
                </Space>
              </div>
            </Dragger>
          </div>
        </Col>
        <Col span={24} className='text-right mt-1'>
          <Button type='primary' icon={<ImportOutlined />} shape='round' loading={loading} onClick={onFinish}>{t('import')}</Button>
        </Col>
      </Row>
    </Card>
  );
}

export default ScCancell;
