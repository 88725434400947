import {ReactNode} from "react";
import {Col, Row} from "antd";

function LayoutPublic({ children }: { children: ReactNode}) {

  return (
    <Row className="min-vh-100">
      <Col span={24}>
        { children }
      </Col>
    </Row>
  );
}

export default LayoutPublic;
