import React, { useEffect, useState } from 'react';
import { Button, Badge, Modal, Row, Col, Form, Select, Space, Slider } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { FetchApiInterface } from "../../../../../interfaces/FetchApi.interface";
import { AxiosResponse } from "axios";
import { ResponseInterface } from "../../../../../interfaces/Response.interface";
import useFetch from "../../../../../hooks/useFetch";
import useMessageUser from "../../../../../hooks/useMessageUser";
import {useTranslation} from "react-i18next";

const { Option } = Select;

interface FiltersProps {
  openModal: any;
  setOpenModal: Function;
  filterApply: any;
  onFinish: (values: any) => void;
  cleanFilters: () => void;
}

const Filters: React.FC<FiltersProps> = ({ filterApply, onFinish, cleanFilters, openModal, setOpenModal }) => {
  const [form] = Form.useForm();
  const { fetchApi, loading } = useFetch();
  const [statusOptions, setStatusOptions] = useState([]);
  const [catalogsOptions, setCatalogsOptions] = useState([]);
  const [PUOptions, setPUOptions] = useState([]);
  const [communitiesOptions, setCommunitiesOptions] = useState([]);
  const { showMessage }  = useMessageUser();
  const { t } = useTranslation();

  useEffect(() => {
    getCatalosList();
    getStatusList();
    getPuList();
  }, []);

  const clear = () => {
    form.resetFields();
    cleanFilters();
  }

  const getCatalosList = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/status-catalog`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setCatalogsOptions(data?.data || []);
        } else {
          setCatalogsOptions([]);
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      setCatalogsOptions([]);
      showMessage(reason?.message, 'error');
    });
  }

  const getStatusList = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/comp_5/get_status`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setStatusOptions(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }
  const getPuList = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/comp_5/get_pu`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setPUOptions(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }
  const getCommunitiesCodes = (puCode: number) => {
    form.setFieldsValue({ community: null });
    setCommunitiesOptions([]);
    const dataSend: FetchApiInterface = {
      url: `/comms/comp_5/get_community`,
      method: 'POST',
      data: { puCode }
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setCommunitiesOptions(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  return (
    <Col>
      <Badge dot={(Object.keys(filterApply)?.length > 0)}>
        <Button type='primary' ghost icon={<FileExcelOutlined />} onClick={() => setOpenModal(true)}>
          {t('filters')}
        </Button>
      </Badge>
      <Modal width={600} title={t('filters')} open={openModal} onCancel={() => setOpenModal(false)} footer={
        <Row justify='space-between'>
          <Col>
            <Button shape='round' type='primary' danger ghost onClick={clear}>{t('clearFilters')}</Button>
          </Col>
          <Col>
            <Space>
              <Button shape='round' type='text' onClick={() => setOpenModal(false)}>{t('cancel')}</Button>
              <Button shape='round' type='primary' onClick={() => form.submit()}>{t('applyFilters')}</Button>
            </Space>
          </Col>
        </Row>
      }>
        <Row>
          <Col span={24}>
            <Form layout='vertical' form={form} onFinish={onFinish}>
              <Row gutter={[10, 0]}>
                <Col span={24}>
                  <Form.Item name='genre' label={t('genre')}>
                    <Select allowClear showSearch placeholder={t('selectGenre')}>
                      <Option value="Male">{t('male')}</Option>
                      <Option value="Female">{t('female')}</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='age' label={t('age')}>
                    <Slider range marks={{ 0: '0', 20: '20' }} min={0} max={20} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='statusSc' label={t('scStatus')}>
                    <Select allowClear showSearch placeholder={t('selectOption')}>
                      {
                        statusOptions.map((option: any) => (
                          <Option key={option.status} value={option.status}>
                            {t(option.status)}
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='statusBoleta' label={t('boletaStatus')}>
                    <Select allowClear showSearch placeholder={t('selectOption')}>
                      {
                        catalogsOptions.map((option: any) => (
                          <Option key={option.scaId} value={option.scaName}>
                            { t(option?.scaName) }
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='pu' label={t('pu')}>
                    <Select allowClear showSearch placeholder={t('selectPu')} onChange={getCommunitiesCodes}>
                      {
                        PUOptions.map((option: any) => (
                          <Option key={option.puCode} value={option.puCode}>
                            {option.puCode}
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                {
                  (communitiesOptions.length > 0) && (
                    <Col span={24}>
                      <Form.Item name='community' label={t('community')}>
                        <Select allowClear showSearch placeholder={t('selectCommunity')}>
                          {
                            communitiesOptions.map((option: any) => (
                              <Option key={option.communityName} value={option.communityName}>
                                {t(option.communityName)}
                              </Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  )
                }
                <Col span={24}>
                  <Form.Item name='module' label={t('module')}>
                    <Select allowClear showSearch placeholder={t('selectModule')}>
                      <Option value="Status">{t('status')}</Option>
                      <Option value="Health">{t('health')}</Option>
                      <Option value="Protection">{t('protection')}</Option>
                      <Option value="Education">{t('education')}</Option>
                      <Option value="Nutrition">{t('nutrition')}</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </Col>
  );
};

export default Filters;
