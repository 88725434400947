import React, { useEffect, useState } from 'react';
import {Button, Badge, Modal, Row, Col, Form, Select, Space, Slider, DatePicker} from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { FetchApiInterface } from "../../../../../interfaces/FetchApi.interface";
import { AxiosResponse } from "axios";
import { ResponseInterface } from "../../../../../interfaces/Response.interface";
import useFetch from "../../../../../hooks/useFetch";
import useMessageUser from "../../../../../hooks/useMessageUser";
import useCommunityWorker from "../../hooks/useCommunityWorker";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";

const { Option } = Select;

interface FiltersProps {
  openModal: any;
  setOpenModal: Function;
  filterApply: any;
  onFinish: (values: any) => void;
  cleanFilters: () => void;
}

const Filters6: React.FC<FiltersProps> = ({ filterApply, onFinish, cleanFilters, openModal, setOpenModal }) => {
  const [form] = Form.useForm();
  const { fetchApi, loading } = useFetch();
  const [statusOptions, setStatusOptions] = useState([]);
  const [catalogsOptions, setCatalogsOptions] = useState([]);
  const [communityWorkers, setCommunityWorkers] = useState([]);
  const [PUOptions, setPUOptions] = useState([]);
  const [COMOptions, setCOMOptions] = useState([]);
  const [communitiesOptions, setCommunitiesOptions] = useState([]);
  const [selectedRange, setSelectedRange] = useState<any>([]);
  const [selectedRangeTwo, setSelectedRangeTwo] = useState<any>([]);
  const { showMessage }  = useMessageUser();
  const { t } = useTranslation();

  useEffect(() => {
    if (openModal) {
      getEasyList();
      getCatalosList();
      getStatusList();
      getPuList();
      getCOM();
    }
  }, [openModal]);

  const clear = () => {
    form.resetFields();
    cleanFilters();
  }

  const getEasyList = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/control/get_facilitador`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setCommunityWorkers(data?.data || []);
        } else {
          setCommunityWorkers([]);
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      setCommunityWorkers([]);
      showMessage(reason?.message, 'error');
    });
  }
  const getCatalosList = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/control/get_status`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setCatalogsOptions(data?.data || []);
        } else {
          setCatalogsOptions([]);
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      setCatalogsOptions([]);
      showMessage(reason?.message, 'error');
    });
  }

  const getStatusList = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/control/get_status_sc`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setStatusOptions(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }
  const getCOM = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/control/get_com_type`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setCOMOptions(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }
  const getPuList = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/control/get_pu`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setPUOptions(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }
  const getCommunitiesCodes = (puCode: number) => {
    form.setFieldsValue({ community: null });
    setCommunitiesOptions([]);
    const dataSend: FetchApiInterface = {
      url: `/comms/control/get_communities/${ puCode }`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setCommunitiesOptions(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const handleSegmentClick = (rangeType: any, option: 'one' | 'two') => {
    const today = moment();
    let startDate;

    switch (rangeType) {
      case '24h':
        startDate = today.clone().subtract(24, 'hours');
        break;
      case '7d':
        startDate = today.clone().subtract(7, 'days');
        break;
      case '1m':
        startDate = today.clone().subtract(1, 'months');
        break;
      case '3m':
        startDate = today.clone().subtract(3, 'months');
        break;
      case '6m':
        startDate = today.clone().subtract(6, 'months');
        break;
      case '1y':
        startDate = today.clone().subtract(1, 'years');
        break;
      default:
        startDate = today;
    }
    if (option === 'one') {
      setSelectedRange([startDate, today]);
      form.setFieldsValue({ read: { value1: startDate, value2: today } });
    } else {
      setSelectedRangeTwo([startDate, today]);
      form.setFieldsValue({ newDate: { value1: startDate, value2: today } });
    }
  };

  return (
    <Col>
      <Badge dot={(Object.keys(filterApply)?.length > 0)}>
        <Button type='primary' ghost icon={<FileExcelOutlined />} onClick={() => setOpenModal(true)}>
          {t('filters')}
        </Button>
      </Badge>
      <Modal centered width={600} title={t('filters')} open={openModal} onCancel={() => setOpenModal(false)} footer={
        <Row justify='space-between'>
          <Col>
            <Button shape='round' type='primary' danger ghost onClick={clear}>{t('clearFilters')}</Button>
          </Col>
          <Col>
            <Space>
              <Button shape='round' type='text' onClick={() => setOpenModal(false)}>{t('cancel')}</Button>
              <Button shape='round' type='primary' onClick={() => form.submit()}>{t('applyFilters')}</Button>
            </Space>
          </Col>
        </Row>
      }>
        <Row>
          <Col span={24}>
            <Form layout='vertical' form={form} onFinish={onFinish}>
              <Row gutter={[10, 0]}>
                <Col span={24} md={12}>
                  <Form.Item name='assComType' label={t('com')}>
                    <Select allowClear showSearch placeholder={t('selectOption')}>
                      {
                        COMOptions.map((option: any) => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item name='ascCw' label={t('facilitator')}>
                    <Select allowClear showSearch placeholder={t('selectOption')}>
                      {
                        communityWorkers.map((worker: any, index) => (
                          <Option key={index} value={worker}>{worker}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <p><strong>{t('place')}</strong></p>
                  <Row gutter={[10, 0]}>
                    <Col span={24} md={12}>
                      <Form.Item name='ascPuCode' label='PU'>
                        <Select allowClear showSearch placeholder={t('selectPu')} onChange={getCommunitiesCodes}>
                          {
                            PUOptions.map((option: any) => (
                              <Option key={option} value={option}>
                                {option}
                              </Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} md={12}>
                      <Form.Item name='ascLevel' label={t('community')}>
                        <Select disabled={(communitiesOptions.length <= 0)} allowClear showSearch placeholder={t('selectCommunity')}>
                          {
                            communitiesOptions.map((option: any) => (
                              <Option key={option} value={option}>
                                {option}
                              </Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[10, 0]}>
                    <Col span={24}>
                      <p><strong>{t('receivedUa')}</strong></p>
                    </Col>
                    <Col span={12}>
                      <Form.Item name={['read', 'value1']} label={t('from')}>
                        <DatePicker
                          format="DD-MM-YY"
                          style={{ width: '100%' }}
                          placeholder="dd/mm/aa"
                          value={selectedRange[0]}
                          onChange={(date) => setSelectedRange([date, selectedRange[1]])}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name={['read', 'value2']} label={t('to')}>
                        <DatePicker
                            format="DD-MM-YYYY"
                          style={{ width: '100%' }}
                          placeholder="dd/mm/aa"
                          value={selectedRange[1]}
                          onChange={(date) => setSelectedRange([selectedRange[0], date])}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <p><strong>{t('showLast')}</strong></p>
                      <Button onClick={() => handleSegmentClick('24h', 'one')}>24 h</Button>
                      <Button onClick={() => handleSegmentClick('7d', 'one')}>7 {t('days')}</Button>
                      <Button onClick={() => handleSegmentClick('1m', 'one')}>1 {t('month')}</Button>
                      <Button onClick={() => handleSegmentClick('3m', 'one')}>3 {t('months')}</Button>
                      <Button onClick={() => handleSegmentClick('6m', 'one')}>6 {t('months')}</Button>
                      <Button onClick={() => handleSegmentClick('1y', 'one')}>1 {t('year')}</Button>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[20, 0]}>
                    <Col span={24}>
                      <br/>
                      <p><strong>{t('dueCd')}</strong></p>
                    </Col>
                    <Col span={12}>
                      <Form.Item name={['newDate', 'value1']} label={t('from')}>
                        <DatePicker
                          format="DD/MM/YY"
                          style={{ width: '100%' }}
                          placeholder="dd/mm/aa"
                          value={selectedRangeTwo[0]}
                          onChange={(date) => setSelectedRangeTwo([date, selectedRangeTwo[1]])}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name={['newDate', 'value2']} label={t('to')}>
                        <DatePicker
                          format="DD/MM/YY"
                          style={{ width: '100%' }}
                          placeholder="dd/mm/aa"
                          value={selectedRangeTwo[1]}
                          onChange={(date) => setSelectedRangeTwo([selectedRangeTwo[0], date])}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <p><strong>{t('showLast')}</strong></p>
                      <Button onClick={() => handleSegmentClick('24h', 'two')}>24 h</Button>
                      <Button onClick={() => handleSegmentClick('7d', 'two')}>7 {t('days')}</Button>
                      <Button onClick={() => handleSegmentClick('1m', 'two')}>1 {t('month')}</Button>
                      <Button onClick={() => handleSegmentClick('3m', 'two')}>3 {t('months')}</Button>
                      <Button onClick={() => handleSegmentClick('6m', 'two')}>6 {t('months')}</Button>
                      <Button onClick={() => handleSegmentClick('1y', 'two')}>1 {t('year')}</Button>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <br/>
                  <Row gutter={[10, 0]}>
                    <Col span={24} md={12}>
                      <Form.Item name='status' label={t('letterStatus')}>
                        <Select allowClear showSearch placeholder={t('selectOption')}>
                          {
                            catalogsOptions.map((option: any) => (
                              <Option key={option} value={option}>
                                {option}
                              </Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} md={12}>
                      <Form.Item name='statusSc' label={t('scStatus')}>
                        <Select allowClear showSearch placeholder={t('selectOption')}>
                          {
                            statusOptions.map((option: any) => (
                              <Option key={option} value={option}>
                                {option}
                              </Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </Col>
  );
};

export default Filters6;
