import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import GlobalContex from "./Store/GlobalContext";
import Auth from "./Store/Auth";
import NavBar from "./Store/NavBar";
import Language from "./Pages/Private/Language";
import Catalog from "./Pages/Private/Catalog";
import Device from "./Pages/Private/Device";
import LoginPage from "./Pages/Public/LoginPage";
import Home from "./Pages/Private/Home";
import Users from "./Pages/Private/Users";
import Profile from "./Pages/Private/Profile";
import CurrentLanguage from "./Store/CurrentLanguage";
import LayoutPublic from "./Layouts/Layout.public";
import LayoutPrivate from "./Layouts/Layout.private";
import UpdateLanguages from "./Store/UpdateLanguages";
import Prospects from "./Pages/Private/Prospects";
import DoublePage from "./Pages/Private/DoublePage";
import PossibleAffiliate from "./Pages/Private/PossibleAffiliate";
import LayoutComms from "./Pages/Private/comms/layout";
import ImportModule from "./Pages/Private/comms/pages/Importaciones/ImportModule";
import Monitoring from "./Pages/Private/comms/pages/Scuapp/Monitoring";
import CreateAgenda from "./Pages/Private/comms/pages/Scuapp/CreateAgenda";
import NewStories from "./Pages/Private/comms/pages/Scuapp/NewStories";
import FDCLeadersAssignment from "./Pages/Private/comms/pages/Users/FDCLeadersAssignment";
import CreateXMLSCU from "./Pages/Private/comms/pages/Scuapp/CreateXMLSCU";
import CreateXMLNewStories from "./Pages/Private/comms/pages/Scuapp/CreateXMLNewStories";
import Compromiso2 from "./Pages/Private/comms/pages/compromisos/Compromiso2";
import GroupsPage from "./Pages/Private/comms/pages/Users/GroupsPage";
import PowerBI from "./Pages/Private/PowerBI";
import Compromiso5 from "./Pages/Private/comms/pages/compromisos/Compromiso5";
import Compromiso6 from "./Pages/Private/comms/pages/compromisos/Compromiso6";

function EcoApp() {

  const { auth, setAuth } = Auth();
  const { nav, setNav } = NavBar();
  const { language, setLanguage } = CurrentLanguage();
  const { updateLis, setUpdateLis } = UpdateLanguages();

  const contextInit = {
    user: {
      auth, setAuth
    },
    navBar: {
      nav, setNav
    },
    currentLanguage: {
      language, setLanguage
    },
    updateListLanguages: {
      updateLis,
      setUpdateLis,
    }
  }

  return (
    <GlobalContex.Provider value={contextInit}>
      <Router>
        <Routes>
          <Route path="/authorization-code/*" element={<LayoutPublic children={<LoginPage/>}/>}/>
          <Route path="/power-report" element={<LayoutPrivate children={<PowerBI/>}/>} />
          <Route path="/languages" element={<LayoutPrivate children={<Language/>}/>} />
          <Route path="/catalog" element={<LayoutPrivate children={<Catalog/>}/>} />
          <Route path="/device" element={<LayoutPrivate children={<Device/>}/>} />
          <Route path="/home" element={<LayoutPrivate children={<Home/>}/>} />
          <Route path="/double_affiliate" element={<LayoutPrivate children={<DoublePage/>}/>} />
          <Route path="/possible_affiliate" element={<LayoutPrivate children={<PossibleAffiliate/>}/>} />
          <Route path="/prospects" element={<LayoutPrivate children={<Prospects/>}/>} />
          <Route path="/users" element={<LayoutPrivate children={<Users/>}/>} />
          <Route path="/profile" element={<LayoutPrivate children={<Profile/>}/>} />
          <Route path="/" element={<Navigate replace to="/authorization-code" />} />
          {/* COMMS */}
          <Route path="/compromisos/compromiso_2" element={<LayoutPrivate><LayoutComms children={<Compromiso2 />}/></LayoutPrivate>} />
          <Route path="/compromisos/compromiso_5" element={<LayoutPrivate><LayoutComms children={<Compromiso5 />}/></LayoutPrivate>} />
          <Route path="/compromisos/compromiso_6" element={<LayoutPrivate><LayoutComms children={<Compromiso6 />}/></LayoutPrivate>} />

          {/*<Route path="/graficas/compromiso_2" element={<LayoutPrivate><LayoutComms children={<GraficasCompromiso2 />}/></LayoutPrivate>} />*/}

          <Route path="/importaciones" element={<LayoutPrivate><LayoutComms children={<ImportModule />}/></LayoutPrivate>} />
          <Route path="/scuapp/monitoring" element={<LayoutPrivate><LayoutComms children={<Monitoring />}/></LayoutPrivate>} />
          <Route path="/scuapp/create_agenda" element={<LayoutPrivate><LayoutComms children={<CreateAgenda />}/></LayoutPrivate>} />
          <Route path="/scuapp/create_agenda_new_stories" element={<LayoutPrivate><LayoutComms children={<NewStories />}/></LayoutPrivate>} />
          <Route path="/scuapp/create_XML_SCU" element={<LayoutPrivate><LayoutComms children={<CreateXMLSCU />}/></LayoutPrivate>} />
          <Route path="/scuapp/createXML_new_stories" element={<LayoutPrivate><LayoutComms children={<CreateXMLNewStories />}/></LayoutPrivate>} />
          <Route path="/users/user_groups" element={<LayoutPrivate><LayoutComms children={<GroupsPage />}/></LayoutPrivate>} />
          <Route path="/users/FDC_leaders_assignment" element={<LayoutPrivate><LayoutComms children={<FDCLeadersAssignment />}/></LayoutPrivate>} />
        </Routes>
      </Router>
    </GlobalContex.Provider>
  );
}

export default EcoApp;
