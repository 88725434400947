function PowerBI() {
  return(
    <div>
      <iframe
        title="POWERBI"
        style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
        src="https://app.powerbi.com/reportEmbed?reportId=35d3a8af-a02f-43ae-8b0f-9afa9ac00ca2&autoAuth=true&ctid=96306d57-5eb7-4dc4-a5bd-22a0e5cc0aab"
        allowFullScreen={true}
      />
    </div>
  );
}

export default PowerBI;
