import {useParams} from "react-router-dom";
import {
  Breadcrumb,
  Card,
  Col,
  Row,
  Typography,
} from "antd";
import TableOneCreateAgenda from "../../components/TableOneCreateAgenda";
import TableTwoCreateAgenda from "../../components/TableTwoCreateAgenda";
import {useState} from "react";
import {useTranslation} from "react-i18next";

const { Title, Paragraph } = Typography;

const CreateAgenda = () => {

  const { key } = useParams();
  const [reload, setReload] = useState<any>(0);
  const { t } = useTranslation();

  return(
    <Row justify='center'>
      <Col span={24} className='p-1'>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>SCUAPP</Breadcrumb.Item>
              <Breadcrumb.Item>{key}</Breadcrumb.Item>
            </Breadcrumb>
            <Title level={3}>{t('createScuSciAgenda')}</Title>
            <Paragraph>{t('pendingUpdatesMessage')}</Paragraph>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 30]}>
              <Col span={24}>
                <Card className='border-radius'>
                  <TableOneCreateAgenda setReload={setReload} />
                </Card>
              </Col>
              <Col span={24}>
                <Card className='border-radius'>
                  <TableTwoCreateAgenda reload={reload} />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CreateAgenda;
