import {message} from "antd";
import {useTranslation} from "react-i18next";

function useMessageUser() {

  const { t } = useTranslation();

  const showMessage = (msg: string | any, code = 'success') => {
    // Verificamos que el mensaje no este vacio
    if (!Boolean(msg)) {
      return;
    }

    // Verificamos que el msg no tenga datos a reemplazar
    if (msg.includes('#')) {
      msg = customMessage(msg);
    } else {
      msg = t(msg);
    }

    switch (code) {
      case 'success':
        message.success(msg);
        break;
      case 'info':
        message.info(msg);
        break;
      case 'error':
        message.error(msg);
        break;
      case 'warning':
        message.warning(msg);
        break;
      default:
        message.info(msg);
        break;
    }
  }

  const customMessage = (msg: string): string => {
    const entities = msg.split(' ');
    const codeEntities = entities.filter(item => !item.includes('#'));
    const hashtagEntities = entities.filter(item => item.includes('#'));
    // Revisar si existen casos en los que exista más de un # vs más de un code
    let message = t(codeEntities[0]);
    message = message.replace('*', t(hashtagEntities[0].replace('#', '')));
    return message;
  }

  return { showMessage }
}
export default useMessageUser;
