import {Badge, Button, Col, Divider, Grid, message, Popconfirm, Row, Space, Typography} from "antd";
import {LanguageListInterface} from "../../../interfaces/LanguageList.interface";
import useFetch from "../../../hooks/useFetch";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../interfaces/Response.interface";
import {useTranslation} from "react-i18next";
import useMessageUser from "../../../hooks/useMessageUser";
import {useContext} from "react";
import GlobalContex from "../../../Store/GlobalContext";

const { useBreakpoint } = Grid;

export function ListLanguage({ lng, getLanguages, setEdit }: { lng: LanguageListInterface, getLanguages: any, setEdit: any }) {

  const { md } = useBreakpoint();
  const { t } = useTranslation();
  const { fetchApi, loading } = useFetch();
  const { showMessage }  = useMessageUser();
  const { user: { auth } } = useContext(GlobalContex);


  const deleteLanguage = () => {
    fetchApi({ url: `/language/${lng.lanId}`}).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          getLanguages();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  return (
    <Row gutter={[ 100, 20 ]} style={{ marginBottom: '2rem' }}>
      <Col span={24} md={8}>
        <Space align="center" size="middle">
          <img src={`data:image/svg+xml;base64,${lng.lanIcon}`} height={30} alt=""/>
          <Space direction="vertical" size={0}>
            <Typography.Title level={5} className="m-0">{ lng.lanName }</Typography.Title>
            <Typography.Text strong>
              <Badge status={(lng.statusRegister) ? 'success' : 'error'} text={(lng.statusRegister) ? t('languageActive') : t('languageNoActive')} />
            </Typography.Text>
          </Space>
        </Space>
      </Col>
      <Col className="center-center">
        {
          (!auth?.roles.includes('ROLE_USER') && !auth?.roles.includes('ROLE_GUEST') && !auth?.roles.includes('ROLE_COUNTRY_PROCESS')) && (
            <Space size="middle">
              <Button size="small" type="text" className="btn-border-radius" onClick={() => setEdit(lng)}>
                { t('languageEditBtn') }
              </Button>
              {
                (lng.lanName !== 'English') && (
                  <Popconfirm
                    title={(lng.statusRegister) ?  t('languageDisableMessage')  : t('languageAbleMessage')}
                    onConfirm={deleteLanguage}
                    okText={t('languageAbleMessageYes')}
                    cancelText={t('languageAbleMessageNo')}
                  >
                    <Button size="small" loading={loading} type="text" style={{ color: (lng.statusRegister) ? 'red' : 'green' }} className="btn-border-radius">
                      {(lng.statusRegister) ? t('languageDeleteBtn') : 'Activar'}
                    </Button>
                  </Popconfirm>
                )
              }
            </Space>
          )
        }
      </Col>
      {
        (!md) && (
          <Col span={24}>
            <Divider type="horizontal"/>
          </Col>
        )
      }
    </Row>
  );
}
